import './HeaderContainer.css';
import 'bulma/css/bulma.min.css';
import Select from 'react-select'
import { Component } from 'react';
import ClipLoader from "react-spinners/ClipLoader";

class HeaderContainer extends Component {
    groupStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      };

    groupBadgeStyles = {
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center',
    };

    formatGroupLabel = (data) => (
        <div style={this.groupStyles}>
          <span>{data.label}</span>
          <span style={this.groupBadgeStyles}>{data.options.length}</span>
        </div>
      );

    render() {
        return (
            <div id="headerContainerWrapper">
                <div id="leftHeader">
                    <Select
                        options={this.props.groupedOptions}
                        value={this.props.selectedOption}
                        onChange={(newValue) => this.props.handleDropdownClick(newValue)}
                        formatGroupLabel={this.formatGroupLabel}
                    />
                </div>
                <div id="header">
                    <nav class="level fullHeight">
                    <div class="level-item has-text-centered fullHeight">
                        <ClipLoader size={10} color={"#5436D7"} loading={this.props.loading} speedMultiplier={1.5} />
                    </div>
                    </nav>
                </div>
                <div id="rightHeader">
                    <Select
                        options={this.props.groupedOptions}
                        value={this.props.selectedOption}
                        onChange={(newValue) => this.props.handleDropdownClick(newValue)}
                        formatGroupLabel={this.formatGroupLabel}
                    />
                </div>
            </div>
        );
    }
}

export default HeaderContainer;
