// source: globalMessages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.ProtoArrayOfFilenameAndDiff', null, global);
goog.exportSymbol('proto.ProtoDiffKind', null, global);
goog.exportSymbol('proto.ProtoFilenameAndDiff', null, global);
goog.exportSymbol('proto.ProtoLanguageDiffInfo', null, global);
goog.exportSymbol('proto.ProtoLanguageDiffKind', null, global);
goog.exportSymbol('proto.ProtoLocation', null, global);
goog.exportSymbol('proto.ProtoTextDiffInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProtoLocation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProtoLocation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProtoLocation.displayName = 'proto.ProtoLocation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProtoTextDiffInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.ProtoTextDiffInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProtoTextDiffInfo.displayName = 'proto.ProtoTextDiffInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProtoLanguageDiffInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProtoLanguageDiffInfo.repeatedFields_, null);
};
goog.inherits(proto.ProtoLanguageDiffInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProtoLanguageDiffInfo.displayName = 'proto.ProtoLanguageDiffInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProtoFilenameAndDiff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProtoFilenameAndDiff.repeatedFields_, null);
};
goog.inherits(proto.ProtoFilenameAndDiff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProtoFilenameAndDiff.displayName = 'proto.ProtoFilenameAndDiff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.ProtoArrayOfFilenameAndDiff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.ProtoArrayOfFilenameAndDiff.repeatedFields_, null);
};
goog.inherits(proto.ProtoArrayOfFilenameAndDiff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.ProtoArrayOfFilenameAndDiff.displayName = 'proto.ProtoArrayOfFilenameAndDiff';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProtoLocation.prototype.toObject = function(opt_includeInstance) {
  return proto.ProtoLocation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProtoLocation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProtoLocation.toObject = function(includeInstance, msg) {
  var f, obj = {
    line: jspb.Message.getFieldWithDefault(msg, 1, 0),
    column: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProtoLocation}
 */
proto.ProtoLocation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProtoLocation;
  return proto.ProtoLocation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProtoLocation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProtoLocation}
 */
proto.ProtoLocation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setLine(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setColumn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProtoLocation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProtoLocation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProtoLocation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProtoLocation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLine();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getColumn();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 line = 1;
 * @return {number}
 */
proto.ProtoLocation.prototype.getLine = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProtoLocation} returns this
 */
proto.ProtoLocation.prototype.setLine = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 column = 2;
 * @return {number}
 */
proto.ProtoLocation.prototype.getColumn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProtoLocation} returns this
 */
proto.ProtoLocation.prototype.setColumn = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProtoTextDiffInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.ProtoTextDiffInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProtoTextDiffInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProtoTextDiffInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    editor: jspb.Message.getFieldWithDefault(msg, 1, ""),
    linenumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    highlightrangestart: jspb.Message.getFieldWithDefault(msg, 3, ""),
    highlightrangeend: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProtoTextDiffInfo}
 */
proto.ProtoTextDiffInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProtoTextDiffInfo;
  return proto.ProtoTextDiffInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProtoTextDiffInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProtoTextDiffInfo}
 */
proto.ProtoTextDiffInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEditor(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLinenumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHighlightrangestart(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHighlightrangeend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProtoTextDiffInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProtoTextDiffInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProtoTextDiffInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProtoTextDiffInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEditor();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLinenumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHighlightrangestart();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getHighlightrangeend();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string editor = 1;
 * @return {string}
 */
proto.ProtoTextDiffInfo.prototype.getEditor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProtoTextDiffInfo} returns this
 */
proto.ProtoTextDiffInfo.prototype.setEditor = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string LineNumber = 2;
 * @return {string}
 */
proto.ProtoTextDiffInfo.prototype.getLinenumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProtoTextDiffInfo} returns this
 */
proto.ProtoTextDiffInfo.prototype.setLinenumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string HighlightRangeStart = 3;
 * @return {string}
 */
proto.ProtoTextDiffInfo.prototype.getHighlightrangestart = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProtoTextDiffInfo} returns this
 */
proto.ProtoTextDiffInfo.prototype.setHighlightrangestart = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string HighlightRangeEnd = 4;
 * @return {string}
 */
proto.ProtoTextDiffInfo.prototype.getHighlightrangeend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProtoTextDiffInfo} returns this
 */
proto.ProtoTextDiffInfo.prototype.setHighlightrangeend = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProtoLanguageDiffInfo.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProtoLanguageDiffInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.ProtoLanguageDiffInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProtoLanguageDiffInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProtoLanguageDiffInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    languagediffkind: jspb.Message.getFieldWithDefault(msg, 1, 0),
    leftstart: (f = msg.getLeftstart()) && proto.ProtoLocation.toObject(includeInstance, f),
    leftend: (f = msg.getLeftend()) && proto.ProtoLocation.toObject(includeInstance, f),
    rightstart: (f = msg.getRightstart()) && proto.ProtoLocation.toObject(includeInstance, f),
    rightend: (f = msg.getRightend()) && proto.ProtoLocation.toObject(includeInstance, f),
    textdiffsList: jspb.Message.toObjectList(msg.getTextdiffsList(),
    proto.ProtoTextDiffInfo.toObject, includeInstance),
    optionsMap: (f = msg.getOptionsMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProtoLanguageDiffInfo}
 */
proto.ProtoLanguageDiffInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProtoLanguageDiffInfo;
  return proto.ProtoLanguageDiffInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProtoLanguageDiffInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProtoLanguageDiffInfo}
 */
proto.ProtoLanguageDiffInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.ProtoLanguageDiffKind} */ (reader.readEnum());
      msg.setLanguagediffkind(value);
      break;
    case 2:
      var value = new proto.ProtoLocation;
      reader.readMessage(value,proto.ProtoLocation.deserializeBinaryFromReader);
      msg.setLeftstart(value);
      break;
    case 3:
      var value = new proto.ProtoLocation;
      reader.readMessage(value,proto.ProtoLocation.deserializeBinaryFromReader);
      msg.setLeftend(value);
      break;
    case 4:
      var value = new proto.ProtoLocation;
      reader.readMessage(value,proto.ProtoLocation.deserializeBinaryFromReader);
      msg.setRightstart(value);
      break;
    case 5:
      var value = new proto.ProtoLocation;
      reader.readMessage(value,proto.ProtoLocation.deserializeBinaryFromReader);
      msg.setRightend(value);
      break;
    case 6:
      var value = new proto.ProtoTextDiffInfo;
      reader.readMessage(value,proto.ProtoTextDiffInfo.deserializeBinaryFromReader);
      msg.addTextdiffs(value);
      break;
    case 7:
      var value = msg.getOptionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProtoLanguageDiffInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProtoLanguageDiffInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProtoLanguageDiffInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProtoLanguageDiffInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguagediffkind();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getLeftstart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.ProtoLocation.serializeBinaryToWriter
    );
  }
  f = message.getLeftend();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.ProtoLocation.serializeBinaryToWriter
    );
  }
  f = message.getRightstart();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.ProtoLocation.serializeBinaryToWriter
    );
  }
  f = message.getRightend();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.ProtoLocation.serializeBinaryToWriter
    );
  }
  f = message.getTextdiffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.ProtoTextDiffInfo.serializeBinaryToWriter
    );
  }
  f = message.getOptionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * optional ProtoLanguageDiffKind LanguageDiffKind = 1;
 * @return {!proto.ProtoLanguageDiffKind}
 */
proto.ProtoLanguageDiffInfo.prototype.getLanguagediffkind = function() {
  return /** @type {!proto.ProtoLanguageDiffKind} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.ProtoLanguageDiffKind} value
 * @return {!proto.ProtoLanguageDiffInfo} returns this
 */
proto.ProtoLanguageDiffInfo.prototype.setLanguagediffkind = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional ProtoLocation LeftStart = 2;
 * @return {?proto.ProtoLocation}
 */
proto.ProtoLanguageDiffInfo.prototype.getLeftstart = function() {
  return /** @type{?proto.ProtoLocation} */ (
    jspb.Message.getWrapperField(this, proto.ProtoLocation, 2));
};


/**
 * @param {?proto.ProtoLocation|undefined} value
 * @return {!proto.ProtoLanguageDiffInfo} returns this
*/
proto.ProtoLanguageDiffInfo.prototype.setLeftstart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProtoLanguageDiffInfo} returns this
 */
proto.ProtoLanguageDiffInfo.prototype.clearLeftstart = function() {
  return this.setLeftstart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProtoLanguageDiffInfo.prototype.hasLeftstart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional ProtoLocation LeftEnd = 3;
 * @return {?proto.ProtoLocation}
 */
proto.ProtoLanguageDiffInfo.prototype.getLeftend = function() {
  return /** @type{?proto.ProtoLocation} */ (
    jspb.Message.getWrapperField(this, proto.ProtoLocation, 3));
};


/**
 * @param {?proto.ProtoLocation|undefined} value
 * @return {!proto.ProtoLanguageDiffInfo} returns this
*/
proto.ProtoLanguageDiffInfo.prototype.setLeftend = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProtoLanguageDiffInfo} returns this
 */
proto.ProtoLanguageDiffInfo.prototype.clearLeftend = function() {
  return this.setLeftend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProtoLanguageDiffInfo.prototype.hasLeftend = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional ProtoLocation RightStart = 4;
 * @return {?proto.ProtoLocation}
 */
proto.ProtoLanguageDiffInfo.prototype.getRightstart = function() {
  return /** @type{?proto.ProtoLocation} */ (
    jspb.Message.getWrapperField(this, proto.ProtoLocation, 4));
};


/**
 * @param {?proto.ProtoLocation|undefined} value
 * @return {!proto.ProtoLanguageDiffInfo} returns this
*/
proto.ProtoLanguageDiffInfo.prototype.setRightstart = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProtoLanguageDiffInfo} returns this
 */
proto.ProtoLanguageDiffInfo.prototype.clearRightstart = function() {
  return this.setRightstart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProtoLanguageDiffInfo.prototype.hasRightstart = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional ProtoLocation RightEnd = 5;
 * @return {?proto.ProtoLocation}
 */
proto.ProtoLanguageDiffInfo.prototype.getRightend = function() {
  return /** @type{?proto.ProtoLocation} */ (
    jspb.Message.getWrapperField(this, proto.ProtoLocation, 5));
};


/**
 * @param {?proto.ProtoLocation|undefined} value
 * @return {!proto.ProtoLanguageDiffInfo} returns this
*/
proto.ProtoLanguageDiffInfo.prototype.setRightend = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.ProtoLanguageDiffInfo} returns this
 */
proto.ProtoLanguageDiffInfo.prototype.clearRightend = function() {
  return this.setRightend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.ProtoLanguageDiffInfo.prototype.hasRightend = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated ProtoTextDiffInfo TextDiffs = 6;
 * @return {!Array<!proto.ProtoTextDiffInfo>}
 */
proto.ProtoLanguageDiffInfo.prototype.getTextdiffsList = function() {
  return /** @type{!Array<!proto.ProtoTextDiffInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProtoTextDiffInfo, 6));
};


/**
 * @param {!Array<!proto.ProtoTextDiffInfo>} value
 * @return {!proto.ProtoLanguageDiffInfo} returns this
*/
proto.ProtoLanguageDiffInfo.prototype.setTextdiffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.ProtoTextDiffInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProtoTextDiffInfo}
 */
proto.ProtoLanguageDiffInfo.prototype.addTextdiffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.ProtoTextDiffInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProtoLanguageDiffInfo} returns this
 */
proto.ProtoLanguageDiffInfo.prototype.clearTextdiffsList = function() {
  return this.setTextdiffsList([]);
};


/**
 * map<string, string> Options = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.ProtoLanguageDiffInfo.prototype.getOptionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.ProtoLanguageDiffInfo} returns this
 */
proto.ProtoLanguageDiffInfo.prototype.clearOptionsMap = function() {
  this.getOptionsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProtoFilenameAndDiff.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProtoFilenameAndDiff.prototype.toObject = function(opt_includeInstance) {
  return proto.ProtoFilenameAndDiff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProtoFilenameAndDiff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProtoFilenameAndDiff.toObject = function(includeInstance, msg) {
  var f, obj = {
    filename: jspb.Message.getFieldWithDefault(msg, 1, ""),
    diffkind: jspb.Message.getFieldWithDefault(msg, 2, 0),
    textdiffList: jspb.Message.toObjectList(msg.getTextdiffList(),
    proto.ProtoTextDiffInfo.toObject, includeInstance),
    languagediffList: jspb.Message.toObjectList(msg.getLanguagediffList(),
    proto.ProtoLanguageDiffInfo.toObject, includeInstance),
    basecontents: jspb.Message.getFieldWithDefault(msg, 5, ""),
    headcontents: jspb.Message.getFieldWithDefault(msg, 6, ""),
    prfilecommitstatus: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProtoFilenameAndDiff}
 */
proto.ProtoFilenameAndDiff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProtoFilenameAndDiff;
  return proto.ProtoFilenameAndDiff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProtoFilenameAndDiff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProtoFilenameAndDiff}
 */
proto.ProtoFilenameAndDiff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilename(value);
      break;
    case 2:
      var value = /** @type {!proto.ProtoDiffKind} */ (reader.readEnum());
      msg.setDiffkind(value);
      break;
    case 3:
      var value = new proto.ProtoTextDiffInfo;
      reader.readMessage(value,proto.ProtoTextDiffInfo.deserializeBinaryFromReader);
      msg.addTextdiff(value);
      break;
    case 4:
      var value = new proto.ProtoLanguageDiffInfo;
      reader.readMessage(value,proto.ProtoLanguageDiffInfo.deserializeBinaryFromReader);
      msg.addLanguagediff(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBasecontents(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHeadcontents(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrfilecommitstatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProtoFilenameAndDiff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProtoFilenameAndDiff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProtoFilenameAndDiff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProtoFilenameAndDiff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilename();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDiffkind();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getTextdiffList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.ProtoTextDiffInfo.serializeBinaryToWriter
    );
  }
  f = message.getLanguagediffList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      proto.ProtoLanguageDiffInfo.serializeBinaryToWriter
    );
  }
  f = message.getBasecontents();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHeadcontents();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPrfilecommitstatus();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string filename = 1;
 * @return {string}
 */
proto.ProtoFilenameAndDiff.prototype.getFilename = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProtoFilenameAndDiff} returns this
 */
proto.ProtoFilenameAndDiff.prototype.setFilename = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ProtoDiffKind diffKind = 2;
 * @return {!proto.ProtoDiffKind}
 */
proto.ProtoFilenameAndDiff.prototype.getDiffkind = function() {
  return /** @type {!proto.ProtoDiffKind} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.ProtoDiffKind} value
 * @return {!proto.ProtoFilenameAndDiff} returns this
 */
proto.ProtoFilenameAndDiff.prototype.setDiffkind = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated ProtoTextDiffInfo textDiff = 3;
 * @return {!Array<!proto.ProtoTextDiffInfo>}
 */
proto.ProtoFilenameAndDiff.prototype.getTextdiffList = function() {
  return /** @type{!Array<!proto.ProtoTextDiffInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProtoTextDiffInfo, 3));
};


/**
 * @param {!Array<!proto.ProtoTextDiffInfo>} value
 * @return {!proto.ProtoFilenameAndDiff} returns this
*/
proto.ProtoFilenameAndDiff.prototype.setTextdiffList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.ProtoTextDiffInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProtoTextDiffInfo}
 */
proto.ProtoFilenameAndDiff.prototype.addTextdiff = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.ProtoTextDiffInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProtoFilenameAndDiff} returns this
 */
proto.ProtoFilenameAndDiff.prototype.clearTextdiffList = function() {
  return this.setTextdiffList([]);
};


/**
 * repeated ProtoLanguageDiffInfo languageDiff = 4;
 * @return {!Array<!proto.ProtoLanguageDiffInfo>}
 */
proto.ProtoFilenameAndDiff.prototype.getLanguagediffList = function() {
  return /** @type{!Array<!proto.ProtoLanguageDiffInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProtoLanguageDiffInfo, 4));
};


/**
 * @param {!Array<!proto.ProtoLanguageDiffInfo>} value
 * @return {!proto.ProtoFilenameAndDiff} returns this
*/
proto.ProtoFilenameAndDiff.prototype.setLanguagediffList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.ProtoLanguageDiffInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProtoLanguageDiffInfo}
 */
proto.ProtoFilenameAndDiff.prototype.addLanguagediff = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.ProtoLanguageDiffInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProtoFilenameAndDiff} returns this
 */
proto.ProtoFilenameAndDiff.prototype.clearLanguagediffList = function() {
  return this.setLanguagediffList([]);
};


/**
 * optional string baseContents = 5;
 * @return {string}
 */
proto.ProtoFilenameAndDiff.prototype.getBasecontents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProtoFilenameAndDiff} returns this
 */
proto.ProtoFilenameAndDiff.prototype.setBasecontents = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string headContents = 6;
 * @return {string}
 */
proto.ProtoFilenameAndDiff.prototype.getHeadcontents = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProtoFilenameAndDiff} returns this
 */
proto.ProtoFilenameAndDiff.prototype.setHeadcontents = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string prFileCommitStatus = 7;
 * @return {string}
 */
proto.ProtoFilenameAndDiff.prototype.getPrfilecommitstatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProtoFilenameAndDiff} returns this
 */
proto.ProtoFilenameAndDiff.prototype.setPrfilecommitstatus = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.ProtoArrayOfFilenameAndDiff.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.toObject = function(opt_includeInstance) {
  return proto.ProtoArrayOfFilenameAndDiff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.ProtoArrayOfFilenameAndDiff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProtoArrayOfFilenameAndDiff.toObject = function(includeInstance, msg) {
  var f, obj = {
    filenameanddiffList: jspb.Message.toObjectList(msg.getFilenameanddiffList(),
    proto.ProtoFilenameAndDiff.toObject, includeInstance),
    commitmessageList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    installationid: jspb.Message.getFieldWithDefault(msg, 3, 0),
    owner: jspb.Message.getFieldWithDefault(msg, 4, ""),
    repo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    pullnumber: jspb.Message.getFieldWithDefault(msg, 6, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.ProtoArrayOfFilenameAndDiff}
 */
proto.ProtoArrayOfFilenameAndDiff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.ProtoArrayOfFilenameAndDiff;
  return proto.ProtoArrayOfFilenameAndDiff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.ProtoArrayOfFilenameAndDiff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.ProtoArrayOfFilenameAndDiff}
 */
proto.ProtoArrayOfFilenameAndDiff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.ProtoFilenameAndDiff;
      reader.readMessage(value,proto.ProtoFilenameAndDiff.deserializeBinaryFromReader);
      msg.addFilenameanddiff(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addCommitmessage(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setInstallationid(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwner(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepo(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setPullnumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.ProtoArrayOfFilenameAndDiff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.ProtoArrayOfFilenameAndDiff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.ProtoArrayOfFilenameAndDiff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilenameanddiffList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.ProtoFilenameAndDiff.serializeBinaryToWriter
    );
  }
  f = message.getCommitmessageList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getInstallationid();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getOwner();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRepo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPullnumber();
  if (f !== 0) {
    writer.writeInt64(
      6,
      f
    );
  }
};


/**
 * repeated ProtoFilenameAndDiff filenameAndDiff = 1;
 * @return {!Array<!proto.ProtoFilenameAndDiff>}
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.getFilenameanddiffList = function() {
  return /** @type{!Array<!proto.ProtoFilenameAndDiff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.ProtoFilenameAndDiff, 1));
};


/**
 * @param {!Array<!proto.ProtoFilenameAndDiff>} value
 * @return {!proto.ProtoArrayOfFilenameAndDiff} returns this
*/
proto.ProtoArrayOfFilenameAndDiff.prototype.setFilenameanddiffList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.ProtoFilenameAndDiff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.ProtoFilenameAndDiff}
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.addFilenameanddiff = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.ProtoFilenameAndDiff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProtoArrayOfFilenameAndDiff} returns this
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.clearFilenameanddiffList = function() {
  return this.setFilenameanddiffList([]);
};


/**
 * repeated string commitMessage = 2;
 * @return {!Array<string>}
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.getCommitmessageList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.ProtoArrayOfFilenameAndDiff} returns this
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.setCommitmessageList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.ProtoArrayOfFilenameAndDiff} returns this
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.addCommitmessage = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.ProtoArrayOfFilenameAndDiff} returns this
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.clearCommitmessageList = function() {
  return this.setCommitmessageList([]);
};


/**
 * optional int64 installationId = 3;
 * @return {number}
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.getInstallationid = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProtoArrayOfFilenameAndDiff} returns this
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.setInstallationid = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional string owner = 4;
 * @return {string}
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.getOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProtoArrayOfFilenameAndDiff} returns this
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.setOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string repo = 5;
 * @return {string}
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.getRepo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.ProtoArrayOfFilenameAndDiff} returns this
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.setRepo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional int64 pullNumber = 6;
 * @return {number}
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.getPullnumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.ProtoArrayOfFilenameAndDiff} returns this
 */
proto.ProtoArrayOfFilenameAndDiff.prototype.setPullnumber = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * @enum {number}
 */
proto.ProtoLanguageDiffKind = {
  MOVE: 0,
  REFACTOR: 1,
  PARSED: 2,
  TEXTREGION: 3
};

/**
 * @enum {number}
 */
proto.ProtoDiffKind = {
  TEXT: 0,
  TS: 1,
  CSS: 2
};

goog.object.extend(exports, proto);
