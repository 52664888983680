import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AceScrollbars from './aceScrollbarCustomization'
import ace from "ace-builds/src-noconflict/ace";
import ace_searchbox from "ace-builds/src-noconflict/ext-searchbox";
import "ace-builds/src-noconflict/mode-typescript";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-plain_text";
import "ace-builds/src-noconflict/theme-sqlserver";
import "ace-builds/src-noconflict/theme-xcode";
import { Component } from 'react';
const throttle = require('lodash/throttle');
const proto = require('./globalMessages_pb');
const axios = require('axios').default;
var { LineWidgets } = ace.require("ace/line_widgets");
var Range = ace.require('ace/range').Range;

ace.config.set('basePath', 'ace-builds/src-noconflict');
ace.config.set('modePath', 'ace-builds/src-noconflict');
ace.config.set('themePath', 'ace-builds/src-noconflict');

class FileOptions {
    value;
    options;

    constructor() {
        this.value = "Files";
        this.options = [];
    }
}

class CommitOptions {
    value;
    options;

    constructor() {
        this.value = "Commits";
        this.options = [];
    }
}

class ConnectorInfo {
    leftStartLine///**/;
    leftEndLine///**/;
    rightStartLine///**/;
    rightEndLine///**/;
    constructor(LeftStart, LeftEnd, RightStart, RightEnd) {
        this.leftStartLine = LeftStart;
        this.leftEndLine = LeftEnd;
        this.rightStartLine = RightStart;
        this.rightEndLine = RightEnd;
    }
}

class Diff extends Component {
    constructor(props) {
        super(props);
        this.dropdownValueAndLabels = [];
        this.dropdownSelectedOption = null;
        this.fileOptions = new FileOptions();
        this.commitOptions = new CommitOptions();
        this.state = {
            groupedOptions: null,
        }

        this.leftEditor = null;
        this.rightEditor = null;

        this._leftMarkerIds = []
        this._rightMarkerIds = [];

        this.protoFilenameAndDiffObjects = [];
        this.commits = [];
        this.filenamesToProtoFilenameAndDiffObjectsIndex = new Map();
        this.filenameToBaseAndHeadContents = new Map();
        this.commitMessageToCommitsArrayIndex = new Map();
        this.currentSelectedFile = null;
        this.installationId = null;
        this.owner = null;
        this.repo = null;
        this.pull_number = null;

        // Text Rendering
        this.rightHighlightedLines = new Set();
        this.leftHighlightedLines = new Set();

        // Language Rendering
        // this._leftMarkerIds = []; // May not be needed since clearing is easy in React
        // this._rightMarkerIds = [];
        this.leftMoveHighlightToRightMoveHighlightMap = new Map();
        this.leftDeletedParsedRegionHighlightToRightHighlightMap = new Map();
        this.rightAddedParsedRegionHighlightToLeftHighlightMap = new Map();//();
        this.rightMoveHighlightToLeftMoveHighlightMap = new Map();
        this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap = new Map();//<number, ConnectorInfo>()
        this._leftEditorNumberOfVirtualLinesOnTop = 0;
        this._rightEditorNumberOfVirtualLinesOnTop = 0;
        this._leftEditorDeletedLinesWidget = new Map();//<number, any/*LineWidget*/>; // Striped deleted lines on the left
        this._rightEditorAddedLinesWidget = new Map();// <number, any>/*LineWidget*/; // Striped added lines on the left
        this._leftLineWidget = null; // Empty lines at the top of the left editor if any
        this._rightLineWidget = null; // Empty lines at the top of the left editor if any
        this._ignoreScrollEvent = false; // Set when we trigger the scroll events manually. Is never set when a user scrolls
    }

    getModeFromProtoFilenameAndDiff(protoFilenameAndDiff) {
        if (protoFilenameAndDiff.diffkind === proto.ProtoDiffKind.TS) {
            return "ace/mode/typescript";
        } else if (protoFilenameAndDiff.diffkind === proto.ProtoDiffKind.CSS) {
            return "ace/mode/css";
        } else {
            return "ace/mode/text";
        }
    }

    _deleteCustomScrollbarHighlights(isLeft) {
        /*
	e.container.insertAdjacentHTML("beforeend", `<div id='ace_pre-v-${isLeft ? 'left' : 'right'}' class='ace_scroll-v-${isLeft ? 'left' : 'right'}'></div><div id='ace_bar-v-${isLeft ? 'left' : 'right'}' class='ace_scroll-v ace_thumb-v'></div>`)
	e.container.insertAdjacentHTML("beforeend", `<div id='ace_pre-h-${isLeft ? 'left' : 'right'}' class='ace_scroll-h-${isLeft ? 'left' : 'right'}'></div><div id='ace_bar-h-${isLeft ? 'left' : 'right'}' class='ace_scroll-h ace_thumb-h'></div>`)
	e.container.insertAdjacentHTML("beforeend", `<div id='ace_map-row-${isLeft ? 'left' : 'right'}' class='ace_map-row'></div>`)
	e.container.insertAdjacentHTML("beforeend", `<div id='ace_map-${isLeft ? 'left' : 'right'}'></div>`)
        */
       let element = document.getElementById(`ace_pre-v-${isLeft ? 'left' : 'right'}`)
       if (element) {
           element.parentNode.removeChild(element);
       }
       element = document.getElementById(`ace_bar-v-${isLeft ? 'left' : 'right'}`)
       if (element) {
           element.parentNode.removeChild(element);
       }
       element = document.getElementById(`ace_bar-h-${isLeft ? 'left' : 'right'}`)
       if (element) {
           element.parentNode.removeChild(element);
       }
       element = document.getElementById(`ace_pre-h-${isLeft ? 'left' : 'right'}`)
       if (element) {
           element.parentNode.removeChild(element);
       }
       element = document.getElementById(`ace_map-row-${isLeft ? 'left' : 'right'}`)
       if (element) {
           element.parentNode.removeChild(element);
       }
       element = document.getElementById(`ace_map-${isLeft ? 'left' : 'right'}`) // Will this delete all its children?
       if (element) {
           element.parentNode.removeChild(element);
       }
    }

    clearTextMarkers() {
        this._leftMarkerIds.forEach((value) => {
            this._getLeftEditor().session.removeMarker(value);
        });
        this._rightMarkerIds.forEach((value) => {
            this._getRightEditor().session.removeMarker(value);
        });

        this._leftMarkerIds = [];
        this._rightMarkerIds = [];
        this.leftHighlightedLines.clear();
        this.rightHighlightedLines.clear();
        // Delete the custom scrollbar lines
        this._deleteCustomScrollbarHighlights(true);
        this._deleteCustomScrollbarHighlights(false);
        this._getLeftEditor().renderer.updateFull(true);
        this._getRightEditor().renderer.updateFull(true);
    }

    clearLanguageMarkers() {
        this._leftMarkerIds.forEach((value) => {
            this._getLeftEditor().session.removeMarker(value);
        });

        this._rightMarkerIds.forEach((value) => {
            this._getRightEditor().session.removeMarker(value);
        });

        this._leftMarkerIds = [];
        this._rightMarkerIds = [];

        this._getLeftEditor().renderer.updateFull(true);
        this._getRightEditor().renderer.updateFull(true);

        // Currently, this is called after the editor is cleared. However, if it is called in the future without clearing the editor, it should still work.
        this.leftMoveHighlightToRightMoveHighlightMap.forEach((rightHighlight, leftHighlight) => {
            var leftElement = document.getElementsByClassName("leftLineMoveHighlightMarker" + leftHighlight.toString())[0];
            var rightElement = document.getElementsByClassName("rightLineMoveHighlightMarker" + rightHighlight.toString())[0];

            leftElement?.removeEventListener("mouseenter", ev => this.handleLeftElementMouseEnterEvent(ev, false));
            leftElement?.removeEventListener("wheel", throttle(e => this.handleLeftElementMouseWheelEvent(e, false), 10, { leading: true, trailing: false }));
            leftElement?.removeEventListener("click", /*debounce*/(e => this.handleLeftElementMouseClickEvent(e, false)));
            leftElement?.removeEventListener("mouseleave", e => this.handleLeftElementMouseLeaveEvent(e, false));
            leftElement?.removeAttribute("mouseAndWheelEventSet");

            rightElement?.removeEventListener("mouseenter", e => this.handleRightElementMouseEnterEvent(e, false));
            rightElement?.removeEventListener("wheel", throttle(e => this.handleRightElementMouseWheelEvent(e, false), 10, { leading: true, trailing: false }));
            rightElement?.removeEventListener("click", /*debounce*/(e => this.handleRightElementMouseClickEvent(e, false)));
            rightElement?.removeEventListener("mouseleave", e => this.handleRightElementMouseLeaveEvent(e, false));
            rightElement?.removeAttribute("mouseAndWheelEventSet");
        });

        this.rightAddedParsedRegionHighlightToLeftHighlightMap.forEach((leftIdThatShouldntBeUsed, rightHighlightElementId) => {
            var rightHighlight = rightHighlightElementId;
            var rightElement = document.getElementsByClassName("rightLineMoveHighlightMarker" + rightHighlight.toString())[0];
            rightElement?.removeEventListener("mouseenter", e => this.handleRightElementMouseEnterEvent(e, true));
            rightElement?.removeEventListener("mouseleave", e => this.handleRightElementMouseLeaveEvent(e, true));
            rightElement?.removeEventListener("wheel", throttle(e => this.handleRightElementMouseWheelEvent(e, true), 10, {leading: true, trailing: false}));
            rightElement?.removeEventListener("click", /*debounce*/(e => this.handleRightElementMouseClickEvent(e, true)));
        });
        this.leftDeletedParsedRegionHighlightToRightHighlightMap.forEach((rightIdThatShouldntBeUsed, leftHighlightElementId) => {
            var leftHighlight = leftHighlightElementId;
            var leftElement = document.getElementsByClassName("leftLineMoveHighlightMarker" + leftHighlight.toString())[0];
            leftElement?.removeEventListener("mouseenter", e => this.handleLeftElementMouseEnterEvent(e, true));
            leftElement?.removeEventListener("mouseleave", e => this.handleLeftElementMouseLeaveEvent(e, true));
            leftElement?.removeEventListener("wheel", throttle(e => this.handleLeftElementMouseWheelEvent(e, true), 10, {leading: true, trailing: false}));
            leftElement?.removeEventListener("click", /*debounce*/(e => this.handleLeftElementMouseClickEvent(e, true)));
        });

        this.leftMoveHighlightToRightMoveHighlightMap.clear();
        this.rightMoveHighlightToLeftMoveHighlightMap.clear();
        this.leftDeletedParsedRegionHighlightToRightHighlightMap.clear();
        this.rightAddedParsedRegionHighlightToLeftHighlightMap.clear();
        this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.clear();
        this._leftEditorDeletedLinesWidget = null;
        this._rightEditorAddedLinesWidget = null;
        if (this._leftLineWidget) {
            this.deleteVirtualLines(false);
            this._getLeftEditor().renderer.updateFull(true);
        }
        if (this._rightLineWidget) {
            this.deleteVirtualLines(true);
            this._getRightEditor().renderer.updateFull(true);
        }
    }

    getTextDiffMarkers(diffs, highlightFullLine, skipLeftLineDelegate, skipRightLineDelegate, leftHighlightRangeLines, rightHighlightRangeLines) {
        diffs.forEach((value) => {
            var editorString = value.editor;
            if (editorString === "leftEditorId") {
                console.log(editorString, value.linenumber);
                this._leftMarkerIds.push(this.leftEditor.session.addMarker(new Range(value.linenumber, value.highlightrangestart, value.linenumber, value.highlightrangeend), "highlightRangeLeftEditor", "text", true));
                leftHighlightRangeLines.push(value.linenumber); // Different from this.leftHighlightedLines

                if (highlightFullLine) {
                    let lineNumber = parseInt(value.linenumber);
                    if (skipLeftLineDelegate) {
                        if (skipLeftLineDelegate(value.linenumber)) {
                            return;
                        }
                    }
                    if (!this.leftHighlightedLines.has(lineNumber)) {
                        console.log("highlightLineLeftEditor" + value.linenumber);
                        this._leftMarkerIds.push(this.leftEditor.session.addMarker(new Range(value.linenumber, 0, value.linenumber, 1), "highlightLineLeftEditor", "fullLine", true));
                        this.leftHighlightedLines.add(lineNumber);
                    }
                }
            } else {
                console.log(editorString, value.linenumber);
                this._rightMarkerIds.push(this._getRightEditor().session.addMarker(new Range(value.linenumber, value.highlightrangestart, value.linenumber, value.highlightrangeend), "highlightRangeRightEditor", "text", true));
                rightHighlightRangeLines.push(value.linenumber);
                if (highlightFullLine) {
                    let lineNumber = parseInt(value.linenumber);
                    if (skipRightLineDelegate) {
                        if (skipRightLineDelegate(value.linenumber)) {
                            return;
                        }
                    }
                    if (!this.rightHighlightedLines.has(lineNumber)) {
                        console.log("highlightLineRightEditor" + value.linenumber);
                        this._rightMarkerIds.push(this._getRightEditor().session.addMarker(new Range(value.linenumber, 0, value.linenumber, 1), "highlightLineRightEditor", "fullLine", true));
                        this.rightHighlightedLines.add(lineNumber);
                    }
                }
            }
        });
    }

    getHashCode2(number1, number2) {
        var hash = 23;
        hash = hash * 31 + number1;
        hash = hash * 31 + number2;
        return hash;
    }

    _getLeftEditor() {
        return this.leftEditor;
    }

    _getRightEditor() {
        return this.rightEditor;
        // let reactAceComponent = this.refs.rightEditor;
        // return reactAceComponent.editor;
    }

    getLineFromEditor(lineNumber, isRight) {
        let editor = isRight ? this._getRigthEditor() : this._getLeftEditor();
        editor.session.getLine(lineNumber)
    }

    getLanguageDiffMarkers(diffs, leftHighlightRangeLines, rightHighlightRangeLines, diffLanguage) {
        // this._cachedTextRendererForClearEditors = new TextRenderer(this._getLeftEditor(), this._getRightEditor());
        var leftMoveHighlightLineToMarkerMap = new Map();
        var rightMoveHighlightLineToMarkerMap = new Map();
        var leftMoveHighlightLineMarkerChild = 0;
        var rightMoveHighlightLineMarkerChild = 0;
        diffs.forEach((value) => {
            if (value.languagediffkind === proto.ProtoLanguageDiffKind.MOVE) {
                // This is a pure move. Just highlight the lines in the editors
                var leftMarkerId;
                var rightMarkerId;
                // ASSUMPTION: value.leftstart.line is always matched only to value.leftend.line
                if (!leftMoveHighlightLineToMarkerMap.has(value.leftstart.line)) {
                    this._leftMarkerIds.push(this._getLeftEditor().session.addMarker(new Range(value.leftstart.line, value.leftstart.column, value.leftend.line, value.leftend.column), "leftLineMoveHighlight" + " leftLineMoveHighlightMarker" + leftMoveHighlightLineMarkerChild.toString(), "fullLine", true));
                    leftMarkerId = leftMoveHighlightLineMarkerChild;
                    leftMoveHighlightLineToMarkerMap.set(value.leftstart.line, leftMoveHighlightLineMarkerChild);
                    leftMoveHighlightLineMarkerChild++;
                } else {
                    leftMarkerId = leftMoveHighlightLineToMarkerMap.get(value.leftstart.line);
                }

                // ASSUMPTION: value.rightstart.line is always matched only to value.rightend.line
                if (!rightMoveHighlightLineToMarkerMap.has(value.rightstart.line)) {
                    this._rightMarkerIds.push(this._getRightEditor().session.addMarker(new Range(value.rightstart.line, value.rightstart.column, value.rightend.line, value.rightend.column), "rightLineMoveHighlight" + " rightLineMoveHighlightMarker" + rightMoveHighlightLineMarkerChild.toString(), "fullLine", true));
                    rightMarkerId = rightMoveHighlightLineMarkerChild;
                    rightMoveHighlightLineToMarkerMap.set(value.rightstart.line, rightMoveHighlightLineMarkerChild);
                    rightMoveHighlightLineMarkerChild++;
                } else {
                    rightMarkerId = rightMoveHighlightLineToMarkerMap.get(value.rightstart.line);
                }

                this.leftMoveHighlightToRightMoveHighlightMap.set(leftMarkerId, rightMarkerId);
                this.rightMoveHighlightToLeftMoveHighlightMap.set(rightMarkerId, leftMarkerId);
                var leftAndRightMoveHighlightMarkersHashCode = this.getHashCode2(leftMarkerId, rightMarkerId);
                this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.set(leftAndRightMoveHighlightMarkersHashCode, new ConnectorInfo(value.leftstart.line, value.leftend.line, value.rightstart.line, value.rightend.line));

                function lineContainsOnlyNewLineChars(lineNumber, isLeftEditor) {
                    let ret = true;
                    let contents = isLeftEditor ? this.getLineFromEditor(lineNumber, false) : this.getLineFromEditor(lineNumber, true);
                    for (let i = 0; i < contents.length; i++) {
                        if (contents[i] !== `\n`/* TODO: EOL */) {
                            ret = false;
                            break;
                        }
                    }
                    return ret;
                }
                function skipMarkingThisLeftLine(lineNumber) {
                    // Only allow highlights for those line that are inside the braces. Lines that are outside the braces, multiple property definitions with comments inside them for example, should not be highlighted if they are part of a move element. However, we still want to highlight lines that contain only newline changes
                    // Here we're only checking before and at the start line. Checking for at and beyond the closing brace line does not work well for new lines that are added after the closing brace line
                    if ((diffLanguage === proto.ProtoDiffKind.CSS && lineNumber <= value.leftstart.line && !lineContainsOnlyNewLineChars(lineNumber, true)) || (diffLanguage === proto.ProtoDiffKind.TS && value.options && value.options.has("TsLeftNodeStartLine") && lineNumber <= parseInt(value.options.get("TsLeftNodeStartLine")) && !lineContainsOnlyNewLineChars(lineNumber, true))) {
                        return true;
                    }
                    return false;
                }
                function skipMarkingThisRightLine(lineNumber) {
                    // Only allow highlights for those line that are inside the braces. Lines that are outside the braces, multiple property definitions for example, should not be highlighted if they are part of a move element
                    // Here we're only checking before and at the start line. Checking for at and beyond the closing brace line does not work well for new lines that are added after the closing brace line
                    if ((diffLanguage === proto.ProtoDiffKind.CSS && lineNumber <= value.rightstart.line && !lineContainsOnlyNewLineChars(lineNumber, false)) || (diffLanguage === proto.ProtoDiffKind.TS && value.options && value.options.has("TsRightNodeStartLine") && lineNumber <= parseInt(value.options.get("TsRightNodeStartLine")) && !lineContainsOnlyNewLineChars(lineNumber, false))) {
                        return true;
                    }
                    return false;
                }

                if (value.textdiffsList) {
                    this.getTextDiffMarkers(value.textdiffsList, true, skipMarkingThisLeftLine, skipMarkingThisRightLine, leftHighlightRangeLines, rightHighlightRangeLines);
                }
            } else if (value.languagediffkind === proto.ProtoLanguageDiffKind.TEXTREGION) {
                // This is actually a text diff using the move infrastructure to highlight the diff regions. Just highlight the lines in the editors
                var leftMarkerId;
                var rightMarkerId;
                // We don't have to worry about duplicates here. The text regions will be unique
                this._leftMarkerIds.push(this._getLeftEditor().session.addMarker(new Range(value.leftstart.line, value.leftstart.column, value.leftend.line, value.leftend.column), "leftTextRegionHighlight" + " leftLineMoveHighlightMarker" + leftMoveHighlightLineMarkerChild.toString(), "fullLine", true));
                leftMarkerId = leftMoveHighlightLineMarkerChild;
                leftMoveHighlightLineMarkerChild++;

                this._rightMarkerIds.push(this._getRightEditor().session.addMarker(new Range(value.rightstart.line, value.rightstart.column, value.rightend.line, value.rightend.column), "rightTextRegionHighlight" + " rightLineMoveHighlightMarker" + rightMoveHighlightLineMarkerChild.toString(), "fullLine", true));
                rightMarkerId = rightMoveHighlightLineMarkerChild;
                rightMoveHighlightLineMarkerChild++;

                this.leftMoveHighlightToRightMoveHighlightMap.set(leftMarkerId, rightMarkerId);
                this.rightMoveHighlightToLeftMoveHighlightMap.set(rightMarkerId, leftMarkerId);
                var leftAndRightMoveHighlightMarkersHashCode = this.getHashCode2(leftMarkerId, rightMarkerId);
                this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.set(leftAndRightMoveHighlightMarkersHashCode, new ConnectorInfo(value.leftstart.line, value.leftend.line, value.rightstart.line, value.rightend.line));
                if (value.textdiffsList) {
                    this.getTextDiffMarkers(value.textdiffsList, true, null, null, leftHighlightRangeLines, rightHighlightRangeLines);
                }
            } else if (value.languagediffkind === proto.ProtoLanguageDiffKind.PARSED) {
                if (value.leftstart.line === -1 && value.leftstart.column === -1 && value.leftend.line === -1 && value.leftend.column === -1) {
                    // Element added to the right.
                    var leftMarkerId = -1;
                    var rightMarkerId;
                    this._rightMarkerIds.push(this._getRightEditor().session.addMarker(new Range(value.rightstart.line, value.rightstart.column, value.rightend.line, value.rightend.column), "rightAddedRegionHighlight" + " rightLineMoveHighlightMarker" + rightMoveHighlightLineMarkerChild.toString(), "fullLine", true));
                    rightMarkerId = rightMoveHighlightLineMarkerChild;
                    rightMoveHighlightLineMarkerChild++;
                    this.rightAddedParsedRegionHighlightToLeftHighlightMap.set(rightMarkerId, leftMarkerId);
                    var leftAndRightMoveHighlightMarkersHashCode = this.getHashCode2(leftMarkerId, rightMarkerId);
                    this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.set(leftAndRightMoveHighlightMarkersHashCode, new ConnectorInfo(value.leftstart.line, value.leftend.line, value.rightstart.line, value.rightend.line));
                } else if (value.rightstart.line === -1 && value.rightstart.column === -1 && value.rightend.line === -1 && value.rightend.column === -1) {
                    // Element deleted on the left.
                    var rightMarkerId = -1;
                    var leftMarkerId;
                    this._leftMarkerIds.push(this._getLeftEditor().session.addMarker(new Range(value.leftstart.line, value.leftstart.column, value.leftend.line, value.leftend.column), "leftDeletedRegionHighlight" + " leftLineMoveHighlightMarker" + leftMoveHighlightLineMarkerChild.toString(), "fullLine", true));
                    leftMarkerId = leftMoveHighlightLineMarkerChild;
                    leftMoveHighlightLineMarkerChild++;
                    this.leftDeletedParsedRegionHighlightToRightHighlightMap.set(leftMarkerId, rightMarkerId);
                    var leftAndRightMoveHighlightMarkersHashCode = this.getHashCode2(leftMarkerId, rightMarkerId);
                    this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.set(leftAndRightMoveHighlightMarkersHashCode, new ConnectorInfo(value.leftstart.line, value.leftend.line, value.rightstart.line, value.rightend.line));
                }
                if (value.textdiffsList) {
                    this.getTextDiffMarkers(value.textdiffsList, true, null, null, leftHighlightRangeLines, rightHighlightRangeLines);
                }
            }
        });
    }

    deleteVirtualLines(isRightEditor) {
        if (isRightEditor) {
            if (this._rightLineWidget) {
                var session = this._getRightEditor().getSession();
                session.widgetManager.removeLineWidget(this._rightLineWidget);
                this._rightLineWidget = null;
                this._rightEditorNumberOfVirtualLinesOnTop = 0;
            }
        } else {
            if (this._leftLineWidget) {
                var session = this._getLeftEditor().getSession();
                session.widgetManager.removeLineWidget(this._leftLineWidget);
                this._leftLineWidget = null;
                this._leftEditorNumberOfVirtualLinesOnTop = 0;
            }
        }
    }

    getLineHeight(isLeftEditor) {
        let lineHeight;
        if (!isLeftEditor) {
            lineHeight = this._getRightEditor().renderer.lineHeight;
        } else {
            lineHeight = this._getLeftEditor().renderer.lineHeight;
        }
        return lineHeight;
    }

    addVirtualLines(isRightEditor, scrollToPosition) {
        // We need the minimum number of lines required to allow scrolling by scrollToPosition
        var lineHeight = this.getLineHeight(!isRightEditor);
        var numberOfLines = scrollToPosition / lineHeight;
        var session;
        if (isRightEditor) {
            session = this._getRightEditor().getSession();
        } else {
            session = this._getLeftEditor().getSession();
        }

        if (!session.widgetManager) {
            session.widgetManager = new LineWidgets(session);
            session.widgetManager.attach(isRightEditor ? this._getRightEditor() : this._getLeftEditor());
        }

        var w = session.widgetManager.addLineWidget({
            rowCount: numberOfLines,
            rowsAbove: numberOfLines,
            row: 0,
            column: 0,
        });
        if (isRightEditor) {
            this._rightLineWidget = w;
            this._rightEditorNumberOfVirtualLinesOnTop = numberOfLines;
        } else {
            this._leftLineWidget = w;
            this._leftEditorNumberOfVirtualLinesOnTop = numberOfLines;
        }

        session._emit("changeFold", { data: { start: { row: 0 } } });
    }

    addAdditionOrDeletionVirtualLines(baseStartLine/**/, baseEndLine/**/, isLeftEditor) {
        if (isLeftEditor) {
            // If some virtual lines exist in the left editor (this is not supposed to happen, but does happen sometimes, probably due to how the event loop works), delete them.
            this._leftEditorDeletedLinesWidget?.forEach((value) => {
                (this._getLeftEditor().session).widgetManager.removeLineWidget(value);
            });
            // value.Left*.line/column should be -1 here. Otherwise, something wrong was passed in
            // Add virtual lines to the left editor.
            var numberOfVirtualLinesToAdd = baseEndLine - baseStartLine + 1;
            var session = this._getLeftEditor().session;

            if (!session.widgetManager) {
                session.widgetManager = new LineWidgets(session);
                session.widgetManager.attach(isLeftEditor ? this._getLeftEditor() : this._getRightEditor());
            }

            // value has base coordinates. First convert that to renderer coordinates
            var rightStartRendererLine = this.getRendererLineForBaseLine(baseStartLine, false);
            // ACE seems to add lines below where we point, not above. So, use the startLine instead of endLine
            // rightStartRendererLine is the leftStartRendererLine that needs virtual lines added below it
            var leftStartRendererLine = rightStartRendererLine;// - this._leftEditorNumberOfVirtualLinesOnTop;
            // if (leftEndRendererLine < 0) {
            //     return;
            // }
            var leftBaseLine = this.getBaseLineForRendererLine(leftStartRendererLine, true);
            if (leftBaseLine >= this._getLeftEditor().session.getLength()) {
                // We're trying to add virtual lines past the last base line in the left editor. No go
                return;
            }

            // The following lines were developed for adding permanent virtual lines. Right now, we have temp virtual lines for mouse enter and leave, so comment them out.
            if (false) {
                this._leftBaseLinesWhereVirtualLinesHaveBeenInsertedIndexSet.addIndex(leftBaseLine);
                this._leftEditorNumberOfVirtualLinesInEditor += numberOfVirtualLinesToAdd;
                this._leftRendererLinesWhereVirtualLinesHaveBeenInsertedIndexSet.addIndex(leftBaseLine + this._leftEditorNumberOfVirtualLinesInEditor);
                if (!this._leftEditorBaseLineToNumberOfRendererLinesAbove) {
                    this._leftEditorBaseLineToNumberOfRendererLinesAbove = new Map();
                }
                let preexistingNumberOfVirtualLinesAtThisBaseLine = 0;
                if (this._leftEditorBaseLineToNumberOfRendererLinesAbove.has(leftBaseLine)) {
                    // We've already added some renderer line on this base line. ACE will replace the line widget, so we add the pre-existing renderer lines here to get the correct number of renderer lines.
                    preexistingNumberOfVirtualLinesAtThisBaseLine += this._leftEditorBaseLineToNumberOfRendererLinesAbove.get(leftBaseLine);
                }
                this._leftEditorBaseLineToNumberOfRendererLinesAbove.set(leftBaseLine, this._leftEditorNumberOfVirtualLinesInEditor);
                if (!this._leftEditorRendererLineToNumberOfRendererLinesAbove) {
                    this._leftEditorRendererLineToNumberOfRendererLinesAbove = new Map();
                }
                this._leftEditorRendererLineToNumberOfRendererLinesAbove.set(leftBaseLine + this._leftEditorNumberOfVirtualLinesInEditor, this._leftEditorNumberOfVirtualLinesInEditor);
            }

            var w = session.widgetManager.addLineWidget({
                rowCount: numberOfVirtualLinesToAdd /*+ preexistingNumberOfVirtualLinesAtThisBaseLine*/,
                rowsAbove: numberOfVirtualLinesToAdd /*+ preexistingNumberOfVirtualLinesAtThisBaseLine*/,
                row: leftBaseLine,
                // If we get an answer to https://github.com/ajaxorg/ace/issues/4324, we can update this line
                // el: dom.createElement("div")
            });
            // w.el.className += " DeletedVirtualLines";
            if (!this._leftEditorDeletedLinesWidget) {
                this._leftEditorDeletedLinesWidget = new Map();
            }
            this._leftEditorDeletedLinesWidget.set(leftBaseLine, w);
            this._getLeftEditor().renderer.updateFull(true);
        } else {
            // value.Right*.line/column should be -1 here. Otherwise, something wrong was passed in
            // Add virtual lines to the right editor.
            // If some virtual lines exist in the right editor (this is not supposed to happen, but does happen sometimes, probably due to how the event loop works), delete them. Also, Daku loves Pacho more!
            this._rightEditorAddedLinesWidget?.forEach((value) => {
                (this._getRightEditor().session).widgetManager.removeLineWidget(value);
            });
            var numberOfVirtualLinesToAdd = baseEndLine - baseStartLine + 1;
            var session = this._getRightEditor().getSession();

            if (!session.widgetManager) {
                session.widgetManager = new LineWidgets(session);
                session.widgetManager.attach(isLeftEditor ? this._getLeftEditor() : this._getRightEditor());
            }

            // value has base coordinates. First convert that to renderer coordinates
            var leftStartRendererLine = this.getRendererLineForBaseLine(baseStartLine, true);
            // ACE seems to add lines below where we point, not above. So, use the startLine instead of endLine
            // leftEndRendererLine is the rightEndRendererLine that needs virtual lines added above it
            var rightStartRendererLine = leftStartRendererLine;// - this._rightEditorNumberOfVirtualLinesOnTop;
            // if (rightEndRendererLine < 0) {
            //     return;
            // }
            var rightBaseLine = this.getBaseLineForRendererLine(rightStartRendererLine, false);
            if (rightBaseLine >= this._getRightEditor().session.getLength()) {
                // We're trying to add virtual lines past the last base line in the right editor. No go
                return;
            }

            // The following lines were developed for adding permanent virtual lines. Right now, we have temp virtual lines for mouse enter and leave, so comment them out.
            if (false) {
                this._rightBaseLinesWhereVirtualLinesHaveBeenInsertedIndexSet.addIndex(rightBaseLine);
                this._rightEditorNumberOfVirtualLinesInEditor += numberOfVirtualLinesToAdd;
                this._rightRendererLinesWhereVirtualLinesHaveBeenInsertedIndexSet.addIndex(rightBaseLine + this._rightEditorNumberOfVirtualLinesInEditor);
                if (!this._rightEditorBaseLineToNumberOfRendererLinesAbove) {
                    this._rightEditorBaseLineToNumberOfRendererLinesAbove = new Map();
                }
                let preexistingNumberOfVirtualLinesAtThisBaseLine = 0;
                if (this._rightEditorBaseLineToNumberOfRendererLinesAbove.has(rightBaseLine)) {
                    // We've already added some renderer line on this base line. ACE will replace the line widget, so we add the pre-existing renderer lines here to get the correct number of renderer lines.
                    preexistingNumberOfVirtualLinesAtThisBaseLine += this._rightEditorBaseLineToNumberOfRendererLinesAbove.get(rightBaseLine);
                }
                this._rightEditorBaseLineToNumberOfRendererLinesAbove.set(rightBaseLine, this._rightEditorNumberOfVirtualLinesInEditor);
                if (!this._rightEditorRendererLineToNumberOfRendererLinesAbove) {
                    this._rightEditorRendererLineToNumberOfRendererLinesAbove = new Map();
                }
                this._rightEditorRendererLineToNumberOfRendererLinesAbove.set(rightBaseLine + this._rightEditorNumberOfVirtualLinesInEditor, this._rightEditorNumberOfVirtualLinesInEditor);
            }

            var w = session.widgetManager.addLineWidget({
                rowCount: numberOfVirtualLinesToAdd /*+ preexistingNumberOfVirtualLinesAtThisBaseLine*/,
                rowsAbove: numberOfVirtualLinesToAdd /*+ preexistingNumberOfVirtualLinesAtThisBaseLine*/,
                row: rightBaseLine,
                // If we get an answer to https://github.com/ajaxorg/ace/issues/4324, we can update this line
                // el: dom.createElement("div")
            });
            // w.el.className += " AddedVirtualLines";
            if (!this._rightEditorAddedLinesWidget) {
                this._rightEditorAddedLinesWidget = new Map();
            }
            this._rightEditorAddedLinesWidget.set(rightBaseLine, w);
            this._getRightEditor().renderer.updateFull(true);
        }
    }

    getRendererLineForBaseLine(baseLine, isLeftEditor) {
        return baseLine;
    }

getBaseLineForRendererLine(rendererLine, isLeftEditor) {
        return rendererLine;
    }

    getCurve(startX/**/, startY/**/, endX/**/, endY/**/) {
        const w = endX - startX;
        const halfWidth = startX + (w / 2);

        // now create the curve
        // position it at the initial x,y coords
        // This is of the form "C M,N O,P Q,R" where C is a directive for SVG ("curveto"),
        // M,N are the first curve control point, O,P the second control point
        // and Q,R are the final coords

        return `M ${startX} ${startY} C ${halfWidth},${startY} ${halfWidth},${endY} ${endX},${endY}`;
    }

    // The connectorId is usually the hash of the leftMarkerId and rightMarkerId
    addConnector(baseLeftStartLine/**/, baseLeftEndLine/**/, baseRightStartLine/**/, baseRightEndLine/**/, connectorId/**/, isAddedOrDeletedParsedElement) {
        var leftScrollTop = this._getLeftEditor().getSession().getScrollTop();
        var rightScrollTop = this._getRightEditor().getSession().getScrollTop();

        //  p1   p2
        //
        //  p3   p4

        let connectorYOffset = 1;
        let lineHeight = this._getLeftEditor().renderer.lineHeight;
        let gutter = document.getElementById("gutter");
        let gutterWidth = gutter.clientWidth;

        // If value.leftstart.line === value.leftend.line, highlight atleast 1 line in the left editor
        // TODO: Will this work if the last line in an editor is part of a diff?
        if (baseLeftStartLine === baseLeftEndLine) {
            // leftEndLine++;
        }
        if (baseRightStartLine === baseRightEndLine) {
            // rightEndLine++;
        }

        // If there are virtual lines in the editor, they are always at the top. Convert the base co-ordinates to renderer co-ordinates
        var rendererLeftStartLine = this.getRendererLineForBaseLine(baseLeftStartLine, true),
            rendererLeftEndLine = this.getRendererLineForBaseLine(baseLeftEndLine, true),
            rendererRightStartLine = this.getRendererLineForBaseLine(baseRightStartLine, false),
            rendererRightEndLine = this.getRendererLineForBaseLine(baseRightEndLine, false);

        if (isAddedOrDeletedParsedElement) {
            if (rendererLeftStartLine === -1 && rendererLeftEndLine === -1 && rendererRightStartLine !== -1) {
                // This is a parsed region being added on the left. Our anchor point here is the rendererRightStartLine. This is where the top part of the connector should connect to. There will be rendererRightStartLine+numberOfVirtualLines added below it
                rendererLeftStartLine = rendererRightStartLine/* + this._leftEditorNumberOfVirtualLinesOnTop*/;
                rendererLeftEndLine = rendererRightStartLine + (rendererRightEndLine - rendererRightStartLine)/* + this._leftEditorNumberOfVirtualLinesOnTop*/;
                if (rendererLeftStartLine >= this._getLeftEditor().session.getLength() || rendererLeftEndLine >= this._getLeftEditor().session.getLength() || rendererLeftStartLine < 0 || rendererLeftEndLine < 0) {
                    return;
                    rendererLeftStartLine = this._getLeftEditor().session.getLength();
                    rendererLeftEndLine = this._getLeftEditor().session.getLength();
                }
            }
            if (rendererRightStartLine === -1 && rendererRightEndLine === -1 && rendererLeftStartLine !== -1) {
                // This is a parsed region being added on the right. Our anchor point here is the rendererLeftStartLine. This is where the top part of the connector should connect to. There will be rendererLeftStartLine+numberOfVirtualLines added below it
                rendererRightStartLine = rendererLeftStartLine/* + this._rightEditorNumberOfVirtualLinesOnTop*/;
                rendererRightEndLine = rendererLeftStartLine + (rendererLeftEndLine - rendererLeftStartLine)/* + this._rightEditorNumberOfVirtualLinesOnTop*/;
                if (rendererRightStartLine >= this._getRightEditor().session.getLength() || rendererRightEndLine >= this._getRightEditor().session.getLength() || rendererRightStartLine < 0 || rendererRightEndLine < 0) {
                    return;
                    rendererRightStartLine = this._getRightEditor().session.getLength();
                    rendererRightEndLine = this._getRightEditor().session.getLength();
                }
            }
        }

        if (rendererLeftStartLine === -1 || rendererLeftEndLine === -1 || rendererRightStartLine === -1 || rendererRightEndLine === -1) {
            // Don't have all the info to render a connector. This could be a text diff masquerading as a move diff. For ex: Some lines deleted on the left that have no match on the right, so the rightStart/EndLine = -1
            return;
        }

        if (this._leftEditorNumberOfVirtualLinesOnTop > 0) {
            rendererLeftStartLine += this._leftEditorNumberOfVirtualLinesOnTop;
            rendererLeftEndLine += this._leftEditorNumberOfVirtualLinesOnTop;
        }
        if (this._rightEditorNumberOfVirtualLinesOnTop > 0) {
            rendererRightStartLine += this._rightEditorNumberOfVirtualLinesOnTop;
            rendererRightEndLine += this._rightEditorNumberOfVirtualLinesOnTop;
        }

        const p1_x = -1;
        const p1_y = (rendererLeftStartLine * lineHeight) - leftScrollTop;
        const p2_x = gutterWidth + 1;
        const p2_y = rendererRightStartLine * lineHeight - rightScrollTop;
        const p3_x = -1;
        const p3_y = ((rendererLeftEndLine + 1) * lineHeight) - leftScrollTop + connectorYOffset;
        const p4_x = gutterWidth + 1;
        const p4_y = ((rendererRightEndLine + 1) * lineHeight) - rightScrollTop + connectorYOffset;
        const curve1 = this.getCurve(p1_x, p1_y, p2_x, p2_y);
        const curve2 = this.getCurve(p4_x, p4_y, p3_x, p3_y);

        const verticalLine1 = `L${p2_x},${p2_y} ${p4_x},${p4_y}`;
        const verticalLine2 = `L${p3_x},${p3_y} ${p1_x},${p1_y}`;
        const d = `${curve1} ${verticalLine1} ${curve2} ${verticalLine2}`;

        const el = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        el.setAttribute('d', d);
        el.setAttribute('class', "diffConnector");
        el.id = connectorId.toString();
        let svg = document.getElementById("svg");
        if (svg) {
            let gutter = document.getElementById("gutter");
            svg.setAttribute('height', gutter.clientHeight.toString());
            svg.appendChild(el);
            return svg.children.length - 1;
        }
    }

    handleLeftElementMouseEnterEvent(e/*MouseEvent*/, isDeletedParsedElement) {
        var leftElement = e.currentTarget;
        leftElement.style.opacity = "1";
        var thisLeftElement = parseInt(leftElement.classList[1].slice(/*leftLineMoveHighlightMarker.length=*/27));
        var thisRightElement = -1;
        if (!isDeletedParsedElement) {
            thisRightElement = this.leftMoveHighlightToRightMoveHighlightMap.get(thisLeftElement);
            if (thisRightElement !== null && thisRightElement !== undefined) {
                var rightElement = document.getElementsByClassName("rightLineMoveHighlightMarker" + thisRightElement?.toString())[0];
                if (rightElement) {
                    rightElement.style.opacity = "1";
                }
            } else {
                thisRightElement = -1;
            }
        }
        var hash = this.getHashCode2(thisLeftElement, thisRightElement);
        var connectorInfo = this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.get(hash);
        if (!connectorInfo) {
            // Not sure why this happens yet
            return;
        }
        this.addConnector(connectorInfo.leftStartLine, connectorInfo.leftEndLine, connectorInfo.rightStartLine, connectorInfo.rightEndLine, hash, isDeletedParsedElement);
        if (isDeletedParsedElement) {
            this.addAdditionOrDeletionVirtualLines(connectorInfo.leftStartLine, connectorInfo.leftEndLine, false);
        }
    }

    handleRightElementMouseEnterEvent(e, isAddedParsedElement) {
        var rightElement = e.currentTarget;
        rightElement.style.opacity = "1";
        var thisRightElement = parseInt(rightElement.classList[1].slice(/*rightLineMoveHighlightMarker.length=*/28));
        var thisLeftElement = -1;
        if (!isAddedParsedElement) {
            var thisLeftElement = this.rightMoveHighlightToLeftMoveHighlightMap.get(thisRightElement);
            if (thisLeftElement !== null && thisLeftElement !== undefined) {
                var leftElement = document.getElementsByClassName("leftLineMoveHighlightMarker" + thisLeftElement?.toString())[0];
                if (leftElement) {
                    leftElement.style.opacity = "1";
                }
            } else {
                thisLeftElement = -1;
            }
        }
        var hash = this.getHashCode2(thisLeftElement, thisRightElement);
        // We should have only 1 connector here, so this should be cheap
        var connector = document.getElementById(hash.toString());
        connector?.parentNode.removeChild(connector);
        var connectorInfo = this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.get(hash);
        if (!connectorInfo) {
            // Not sure why this happens yet
            return;
        }
        this.addConnector(connectorInfo.leftStartLine, connectorInfo.leftEndLine, connectorInfo.rightStartLine, connectorInfo.rightEndLine, hash, isAddedParsedElement);
        if (isAddedParsedElement) {
            this.addAdditionOrDeletionVirtualLines(connectorInfo.rightStartLine, connectorInfo.rightEndLine, true);
        }
    }

    handleLeftElementMouseWheelEvent(e, isDeletedParsedElement) {
        var leftElement = e.currentTarget;
        if (!leftElement) {
            return;
        }
        ((e.currentTarget)).style.opacity = "1";
        if (leftElement.classList.length < 2) {
            return;
        }
        var thisLeftElement = parseInt(leftElement.classList[1].slice(/*leftLineMoveHighlightMarker.length=*/27));
        var thisRightElement = -1;
        if (!isDeletedParsedElement) {
            thisRightElement = this.leftMoveHighlightToRightMoveHighlightMap.get(thisLeftElement);
            if (thisRightElement !== null && thisRightElement !== undefined) {
                var rightElement = document.getElementsByClassName("rightLineMoveHighlightMarker" + thisRightElement.toString())[0];
                if (rightElement) {
                    rightElement.style.opacity = "1";
                }
            } else {
                thisRightElement = -1;
            }
        }
        var hash = this.getHashCode2(thisLeftElement, thisRightElement);
        // We should have only 1 connector here, so this should be cheap
        var connector = document.getElementById(hash.toString());
        connector?.parentNode.removeChild(connector);
        var connectorInfo = this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.get(hash);
        if (!connectorInfo) {
            // Not sure why this happens yet
            return;
        }
        this.addConnector(connectorInfo.leftStartLine, connectorInfo.leftEndLine, connectorInfo.rightStartLine, connectorInfo.rightEndLine, hash, isDeletedParsedElement);
        if (isDeletedParsedElement) {
            this.addAdditionOrDeletionVirtualLines(connectorInfo.rightStartLine, connectorInfo.rightEndLine, false);
        }
    }

    handleRightElementMouseWheelEvent(e, isAddedParsedElement) {
        var rightElement = e.currentTarget;
        if (!rightElement) {
            return;
        }
        rightElement.style.opacity = "1";
        if (rightElement.classList.length < 2) {
            // Just being safe
            return;
        }
        var thisRightElement = parseInt(rightElement.classList[1].slice(/*rightLineMoveHighlightMarker.length=*/28));
        var thisLeftElement = -1;
        if (!isAddedParsedElement) {
            thisLeftElement = this.rightMoveHighlightToLeftMoveHighlightMap.get(thisRightElement);
            if (thisLeftElement !== null && thisLeftElement !== undefined) {
                var leftElement = document.getElementsByClassName("leftLineMoveHighlightMarker" + thisLeftElement.toString())[0];
                if (leftElement) {
                    leftElement.style.opacity = "1";
                }
            } else {
                thisLeftElement = -1;
            }
        }
        var hash = this.getHashCode2(thisLeftElement, thisRightElement);
        // We should have only 1 connector here, so this should be cheap
        var connector = document.getElementById(hash.toString());
        connector?.parentNode.removeChild(connector);
        var connectorInfo = this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.get(hash);
        if (!connectorInfo) {
            // Not sure why this happens yet
            return;
        }
        this.addConnector(connectorInfo.leftStartLine, connectorInfo.leftEndLine, connectorInfo.rightStartLine, connectorInfo.rightEndLine, hash, isAddedParsedElement);
        // If this is an added parsed element on the right, create new virtual lines in the left editor here if possible
        if (isAddedParsedElement) {
            this.addAdditionOrDeletionVirtualLines(connectorInfo.leftStartLine, connectorInfo.leftEndLine, true);
        }
    }

    handleLeftElementMouseClickEvent(e, isDeletedParsedElement) {
        switch (e.button) {
            case 0:
                // Left click
                var leftElement = e.target;
                var thisLeftElement = parseInt(leftElement.classList[1].slice(/*leftLineMoveHighlightMarker.length=*/27));
                var thisRightElement = -1;
                if (!isDeletedParsedElement) {
                    thisRightElement = this.leftMoveHighlightToRightMoveHighlightMap.get(thisLeftElement);
                }
                // Get leftElementLine * LineHeight - leftEditor.getScrollTop(). This is the pixel position of the start of the left line on the screen. On the right editor, first scroll to the first line of the rightElement. Then align it to the left line so that it is at the same offset as the leftElement's pixel position
                var hash = this.getHashCode2(thisLeftElement, thisRightElement);
                var connectorInfo = this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.get(hash);
                if (!connectorInfo) {
                    // Not sure why this happens yet
                    return;
                }
                var leftStartLine = connectorInfo.leftStartLine;
                var rightStartLine = connectorInfo.rightStartLine === -1 ? this.getBaseLineForRendererLine(leftStartLine, false) : connectorInfo.rightStartLine;
                // TODO: Bug here if the left editor has virtual lines
                const leftScrollTop = this._getLeftEditor().getSession().getScrollTop();
                let lineHeight = this._getLeftEditor().renderer.lineHeight;
                const leftPixelPosition = ((leftStartLine + this._leftEditorNumberOfVirtualLinesOnTop) * lineHeight) - leftScrollTop;
                if (this._rightLineWidget) {
                    this.deleteVirtualLines(true);
                    this._getRightEditor().renderer.updateFull(true);
                }
                this._ignoreScrollEvent = true;
                this._getRightEditor().scrollToRow(rightStartLine); // Scrolls the line to the top
                var rightEditorScrollTop = this._getRightEditor().getSession().getScrollTop();
                this._getRightEditor().renderer.scrollBy(0, -leftPixelPosition);
                this._getRightEditor().renderer.updateFull(true);
                // If ACE was unable to scroll because it ran out of scroll space, add virtual lines to the top.
                var currentScrollTop = this._getRightEditor().getSession().getScrollTop();
                if (currentScrollTop !== rightEditorScrollTop - leftPixelPosition) {
                    this.addVirtualLines(true, leftPixelPosition - rightEditorScrollTop);
                    this._getRightEditor().renderer.updateFull(true);
                }
                this._ignoreScrollEvent = false;
                // We should have only 1 connector here, so this should be cheap
                var connector = document.getElementById(hash.toString());
                connector?.parentNode.removeChild(connector);
                this.addConnector(connectorInfo.leftStartLine, connectorInfo.leftEndLine, connectorInfo.rightStartLine, connectorInfo.rightEndLine, hash, isDeletedParsedElement);
                break;
            default:
                break;
        }
    }

    handleRightElementMouseClickEvent(e, isAddedParsedElement) {
        // Uses the same logic as leftElement.click event
        switch (e.button) {
            case 0:
                // Left click
                var rightElement = e.target;
                var thisRightElement = parseInt(rightElement.classList[1].slice(/*rightLineMoveHighlightMarker.length=*/28));
                var thisLeftElement = -1;
                if (!isAddedParsedElement) {
                    thisLeftElement = this.rightMoveHighlightToLeftMoveHighlightMap.get(thisRightElement);
                }
                var hash = this.getHashCode2(thisLeftElement, thisRightElement);
                var connectorInfo = this.leftAndRightMoveHighlightMarkersHashCodeToConnectorMap.get(hash);
                if (!connectorInfo) {
                    // Not sure why this happens yet
                    return;
                }
                var rightStartLine = connectorInfo.rightStartLine;
                var leftStartLine = connectorInfo.leftStartLine === -1 ? this.getBaseLineForRendererLine(rightStartLine, true) : connectorInfo.leftStartLine;
                const rightScrollTop = this._getRightEditor().getSession().getScrollTop();
                let lineHeight = this._getRightEditor().renderer.lineHeight;
                const rightPixelPosition = ((rightStartLine + this._rightEditorNumberOfVirtualLinesOnTop) * lineHeight) - rightScrollTop;
                if (this._leftLineWidget) {
                    this.deleteVirtualLines(false);
                    this._getLeftEditor().renderer.updateFull(true);
                }
                this._ignoreScrollEvent = true;
                this._getLeftEditor().scrollToRow(leftStartLine); // Scrolls the line to the top
                var leftEditorScrollTop = this._getLeftEditor().getSession().getScrollTop();
                this._getLeftEditor().renderer.scrollBy(0, -rightPixelPosition);
                this._getLeftEditor().renderer.updateFull(true);
                // If ACE was unable to scroll because it ran out of scroll space, add virtual lines to the top.
                var currentScrollTop = this._getLeftEditor().getSession().getScrollTop();
                if (currentScrollTop !== leftEditorScrollTop - rightPixelPosition) {
                    this.addVirtualLines(false, rightPixelPosition - leftEditorScrollTop);
                    this._getLeftEditor().renderer.updateFull(true);
                }
                this._ignoreScrollEvent = false;
                // We should have only 1 connector here, so this should be cheap
                var connector = document.getElementById(hash.toString());
                connector?.parentNode.removeChild(connector);
                this.addConnector(connectorInfo.leftStartLine, connectorInfo.leftEndLine, connectorInfo.rightStartLine, connectorInfo.rightEndLine, hash, isAddedParsedElement);
                break;
            default:
                break;
        }

    }

    handleLeftElementMouseLeaveEvent(e, isDeletedParsedElement) {
        var leftElement = e.currentTarget;
        leftElement.style.opacity = "0.3";
        if (leftElement.classList.length < 2 || (leftElement.classList[1].indexOf("leftLineMoveHighlightMarker", 0) === -1)) {
            // Sometimes an element is tagged as both a move highlight element and has text diffs, so also as a line highlight element. When the target is a line highlight element, just return
            return;
        }
        var thisLeftElement = parseInt(leftElement.classList[1].slice(/*leftLineMoveHighlightMarker.length=*/27));
        var thisRightElement = -1;
        if (!isDeletedParsedElement) {
            thisRightElement = this.leftMoveHighlightToRightMoveHighlightMap.get(thisLeftElement);
            if (thisRightElement !== null && thisRightElement !== undefined) {
                var rightElement = document.getElementsByClassName("rightLineMoveHighlightMarker" + thisRightElement?.toString())[0];
                if (rightElement) {
                    rightElement.style.opacity = "0.3";
                }
            } else {
                thisRightElement = -1;
            }
        }

        // TODO: This is a potential bug. For languages that have nested moved elements (class containing functions for ex), this may fail. For now, this assumes that we only have 1 diffConnector element and mouseleave always follows a mouseenter. If a mouseenter follows another mouseenter, this will fail and delete all the connectors.
        // We should have only 1 connector here, so this should be cheap
        var hash = this.getHashCode2(thisLeftElement, thisRightElement);
        var connector = document.getElementById(hash.toString());
        connector?.parentNode.removeChild(connector);
        if (isDeletedParsedElement) {
            this._rightEditorAddedLinesWidget?.forEach((value) => {
                (this._getRightEditor().session).widgetManager.removeLineWidget(value);
            });
        }
    }

    handleRightElementMouseLeaveEvent(e, isAddedParsedElement) {
        var rightElement = e.currentTarget;
        rightElement.style.opacity = "0.3";
        if (rightElement.classList.length < 2 || (rightElement.classList[1].indexOf("rightLineMoveHighlightMarker", 0) === -1)) {
            // Sometimes an element is tagged as both a move highlight element and has text diffs, so also as a line highlight element. When the target is a line highlight element, just return
            return;
        }
        var thisRightElement = parseInt(rightElement.classList[1].slice(/*rightLineMoveHighlightMarker.length=*/28));
        var thisLeftElement = -1;
        if (!isAddedParsedElement) {
            thisLeftElement = this.rightMoveHighlightToLeftMoveHighlightMap.get(thisRightElement);
            if (thisLeftElement !== null && thisLeftElement !== undefined) {
                var leftElement = document.getElementsByClassName("leftLineMoveHighlightMarker" + thisLeftElement.toString())[0];
                if (leftElement) {
                    leftElement.style.opacity = "0.3";
                }
            } else {
                thisLeftElement = -1;
            }
        }
        // We should have only 1 connector here, so this should be cheap
        var hash = this.getHashCode2(thisLeftElement, thisRightElement);
        var connector = document.getElementById(hash.toString());
        connector?.parentNode.removeChild(connector);
        if (isAddedParsedElement) {
            this._leftEditorDeletedLinesWidget?.forEach((value) => {
                (this._getLeftEditor().session).widgetManager.removeLineWidget(value);
            });
        }
    }

    checkIfEventListenersAreAlreadyDefined(element) {
        return element.getAttribute("mouseAndWheelEventSet") === "true";
    }

    _handleMoveHighlightOpacityAndConnectorsOnScroll() {
        var connectors = document.getElementsByClassName("diffConnector");

        while (connectors[0]) {
            connectors[0].parentNode.removeChild(connectors[0]);
        }

        this.leftMoveHighlightToRightMoveHighlightMap.forEach((rightHighlight, leftHighlight) => {
            var leftElement = document.getElementsByClassName("leftLineMoveHighlightMarker" + leftHighlight.toString())[0];
            var rightElement = document.getElementsByClassName("rightLineMoveHighlightMarker" + rightHighlight.toString())[0];

            if (leftElement && !this.checkIfEventListenersAreAlreadyDefined(leftElement)) {
                leftElement?.addEventListener("mouseenter", ev => this.handleLeftElementMouseEnterEvent(ev, false));
                leftElement?.addEventListener("wheel", throttle(e => this.handleLeftElementMouseWheelEvent(e, false), 10, { leading: true, trailing: false }));
                leftElement?.addEventListener("click", /*debounce*/(e => this.handleLeftElementMouseClickEvent(e, false)), {
                    once: false, // Setting this to true leads to bugs in Windows. Also, this does not seem to be respected anyway,
                    passive: true
                });
                leftElement?.addEventListener("mouseleave", e => this.handleLeftElementMouseLeaveEvent(e, false));
                leftElement.setAttribute("mouseAndWheelEventSet", "true");
            }
            if (rightElement && !this.checkIfEventListenersAreAlreadyDefined(rightElement)) {
                rightElement?.addEventListener("mouseenter", e => this.handleRightElementMouseEnterEvent(e, false));
                // TODO: Revisit at some point if we want to display the connectors dynamically when scrolling.
                // The wheel event is triggered a lot and leads to bad responsiveness when scrolling in the UI.
                // Throttling doesn't seem to work well. I think this is because the callback is multi threaded maybe and we end up adding hundreds of connectors in the gutter. 
                rightElement?.addEventListener("wheel", throttle(e => this.handleRightElementMouseWheelEvent(e, false), 10, { leading: true, trailing: true }));
                rightElement?.addEventListener("click", /*debounce*/(e => this.handleRightElementMouseClickEvent(e, false)), {
                    once: false, // Setting this to true leads to bugs in Windows. Also, this does not seem to be respected anyway
                    passive: true
                });
                rightElement?.addEventListener("mouseleave", e => this.handleRightElementMouseLeaveEvent(e, false));
                rightElement.setAttribute("mouseAndWheelEventSet", "true");
            }
        });
        this.rightAddedParsedRegionHighlightToLeftHighlightMap.forEach((leftIdThatShouldntBeUsed, rightHighlightElementId) => {
            var rightHighlight = rightHighlightElementId;
            var rightElement = document.getElementsByClassName("rightLineMoveHighlightMarker" + rightHighlight.toString())[0];
            if (rightElement && this.checkIfEventListenersAreAlreadyDefined(rightElement)) {
                return;
            }
            rightElement?.addEventListener("mouseenter", e => this.handleRightElementMouseEnterEvent(e, true));
            rightElement?.addEventListener("mouseleave", e => this.handleRightElementMouseLeaveEvent(e, true));
            rightElement?.addEventListener("wheel", throttle(e => this.handleRightElementMouseWheelEvent(e, true), 10, { leading: true, trailing: true }));
            rightElement?.addEventListener("click", /*debounce*/(e => this.handleRightElementMouseClickEvent(e, true)), {
                once: false, // Setting this to true leads to bugs in Windows. Also, this does not seem to be respected anyway
                passive: true
            });
            rightElement?.setAttribute("mouseAndWheelEventSet", "true");
        });
        this.leftDeletedParsedRegionHighlightToRightHighlightMap.forEach((rightIdThatShouldntBeUsed, leftHighlightElementId) => {
            var leftHighlight = leftHighlightElementId;
            var leftElement = document.getElementsByClassName("leftLineMoveHighlightMarker" + leftHighlight.toString())[0];
            if (leftElement && this.checkIfEventListenersAreAlreadyDefined(leftElement)) {
                return;
            }
            leftElement?.addEventListener("mouseenter", e => this.handleLeftElementMouseEnterEvent(e, true));
            leftElement?.addEventListener("mouseleave", e => this.handleLeftElementMouseLeaveEvent(e, true));
            leftElement?.addEventListener("wheel", throttle(e => this.handleLeftElementMouseWheelEvent(e, true), 10, { leading: true, trailing: false }));
            leftElement?.addEventListener("click", /*debounce*/(e => this.handleLeftElementMouseClickEvent(e, true)), {
                once: false, // Setting this to true leads to bugs in Windows. Also, this does not seem to be respected anyway,
                passive: true
            });
            leftElement?.setAttribute("mouseAndWheelEventSet", "true");
        });
    }

    _setMoveHighlightLineHover() {
        this._handleMoveHighlightOpacityAndConnectorsOnScroll();
        this._getLeftEditor().session.on("changeScrollTop", (scrollTop/**/) => {
            if (!this._ignoreScrollEvent) {
                // BugFix: When the editor is scrolled by the user, the changeScrollTop event is fired before the changes happen in the editor. So force a full render here. Otherwise, the new elements added to the DOM will not have their mouse*, click and wheel events wired up.
                this._getLeftEditor().renderer.updateFull(true);
                this._handleMoveHighlightOpacityAndConnectorsOnScroll();
            }
        });
        this._getRightEditor().session.on("changeScrollTop", (scrollTop/**/) => {
            if (!this._ignoreScrollEvent) {
                // BugFix: When the editor is scrolled by the user, the changeScrollTop event is fired before the changes happen in the editor. So force a full render here. Otherwise, the new elements added to the DOM will not have their mouse*, click and wheel events wired up.
                this._getRightEditor().renderer.updateFull(true);
                this._handleMoveHighlightOpacityAndConnectorsOnScroll();
            }
        });
        // Set to false in production. Turned on only to obtain UI test information
        if (false) {
            let leftHighlightLines = document.getElementsByClassName("highlightLineLeftEditor");
            let leftHighlightRanges = document.getElementsByClassName("highlightRangeLeftEditor");
            let leftTextRegionHighlights = document.getElementsByClassName("leftTextRegionHighlight");
            let leftDeletedHighlights = document.getElementsByClassName("leftDeletedRegionHighlight");

            let rightHighlightLines = document.getElementsByClassName("highlightLineRightEditor");
            let rightHighlightRanges = document.getElementsByClassName("highlightRangeRightEditor");
            let rightTextRegionHighlights = document.getElementsByClassName("rightTextRegionHighlight");
            let rightAddedHighlights = document.getElementsByClassName("rightAddedRegionHighlight");

            // Produces a line formatted with arguments that match UITestDiffInfo
            console.log(leftHighlightLines.length + ",", leftHighlightRanges.length + ",", rightHighlightLines.length + ",", rightHighlightRanges.length + ",", leftDeletedHighlights.length + ",", rightAddedHighlights.length + ",", leftTextRegionHighlights.length + ",", rightTextRegionHighlights.length);

            // Print the move highlight maps
            let moveHighlightMaps = [];
            this.leftMoveHighlightToRightMoveHighlightMap.forEach((rightHighlight, leftHighlight) => {
                moveHighlightMaps.push(leftHighlight.toString(), rightHighlight.toString());
            });
            let parsedRegionMaps = [];
            this.rightAddedParsedRegionHighlightToLeftHighlightMap.forEach((leftIdThatShouldntBeUsed, rightHighlightElementId) => {
                parsedRegionMaps.push(leftIdThatShouldntBeUsed.toString(), rightHighlightElementId.toString());

            });
            this.leftDeletedParsedRegionHighlightToRightHighlightMap.forEach((rightIdThatShouldntBeUsed, leftHighlightElementId) => {
                parsedRegionMaps.push(leftHighlightElementId.toString(), rightIdThatShouldntBeUsed.toString());
            });

            console.log("MoveHighlightMaps:");
            console.log(moveHighlightMaps);
            console.log("ParsedRegionMaps:");
            console.log(parsedRegionMaps);
        }
    }

    _fillGutterWithSpans() {
        let gutter = document.getElementById("gutter");
        // Create an svg div
        let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.id += "svg";
        svg.setAttribute('width', gutter.clientWidth.toString());
        // TODO: Sometimes the gutter height here is not the full gutter height. We compensate for that by getting the gutter height again explicitly in addConnector and resetting svg height, but it'd be nice not to have to do that.
        svg.setAttribute('height', gutter.clientHeight.toString());
        gutter.appendChild(svg);
    }

    async _populateBaseAndHeadContents(filename) {
        if (!this.filenameToBaseAndHeadContents.has(filename)) {
            // Ping GH to get the base contents
            let arrayIndex = this.filenamesToProtoFilenameAndDiffObjectsIndex.get(filename);
            let protoObject = this.protoFilenameAndDiffObjects[arrayIndex];
            let fileContents = await axios.get(`https://wr57ntyz72.execute-api.us-west-2.amazonaws.com/data`,
                {
                    headers: {
                        'Accept': 'application/json'
                    },
                    params: {
                        filename: filename,
                        owner: this.owner,
                        repo: this.repo,
                        pull: this.pull_number,
                        installation: this.installationId,
                        filestatus: this.protoFilenameAndDiffObjects[arrayIndex].prfilecommitstatus
                    }
                })
            let response = fileContents.data;
            let baseContents = response.base;
            let headContents = response.head;
            protoObject.basecontents = baseContents;
            protoObject.headcontents = headContents;
            let obj = {
                head: headContents,
                base: baseContents
            };
            this.filenameToBaseAndHeadContents.set(filename, obj);
        }
    }

    async _selectAFileUsingProtoFilenameAndDiff(protoFilenameAndDiff) {
        await this._populateBaseAndHeadContents(protoFilenameAndDiff.filename)
        let headcontents = protoFilenameAndDiff.headcontents;
        let basecontents = protoFilenameAndDiff.basecontents;
        let mode = this.getModeFromProtoFilenameAndDiff(protoFilenameAndDiff);
        this.leftEditor.session.setValue(basecontents);
        this.rightEditor.session.setValue(headcontents);
        this.leftEditor.session.setMode(mode);
        this.rightEditor.session.setMode(mode);
        let leftHighlightRangeLines = [];
        let rightHighlightRangeLines = [];
        if (protoFilenameAndDiff.textdiffList.length > 0) {
            this.getTextDiffMarkers(protoFilenameAndDiff.textdiffList, true, null, null, leftHighlightRangeLines, rightHighlightRangeLines);
        } else {
            // Handle language diffs
            this.getLanguageDiffMarkers(protoFilenameAndDiff.languagediffList, leftHighlightRangeLines, rightHighlightRangeLines, protoFilenameAndDiff.diffkind)
        }
        // Even though we set the markers in set state, I'm not certain ACE has rendered them here, so force a full render just in case
        this._getLeftEditor().renderer.updateFull(true);
        this._getRightEditor().renderer.updateFull(true);
        this._setMoveHighlightLineHover();
        new AceScrollbars(this.leftEditor, leftHighlightRangeLines, true);
        new AceScrollbars(this.rightEditor, rightHighlightRangeLines, false);
    }

    async selectAFile(filename) {
        this.clearTextMarkers();
        this.clearLanguageMarkers();
        let index = this.filenamesToProtoFilenameAndDiffObjectsIndex.get(filename);
        if (index !== undefined) {
            let protoFilenameAndDiff = this.protoFilenameAndDiffObjects[index];
            await this._selectAFileUsingProtoFilenameAndDiff(protoFilenameAndDiff);
        }

        return index;
    }

    async handleFileClick(newValue) {
        return this.selectAFile(newValue);
    }

    async handleCommitClick(arrayOfProtoFilenameAndDiff, firstFile) {
        this._populateProtoFilenameAndDiffObjectsAndMap(arrayOfProtoFilenameAndDiff, true);
        return this.selectAFile(firstFile);
    }

    // handleDropdownClick(newValue) {
    //     console.log(newValue);
    //     // TODO: Continue from here: Update selectAFile (or this function) to handle both commit messages and file names. This requires some thought. There needs to be some feedback in the UI about which commit is currently selected
    //     // Choosing an individual commit means that we must reset this.fileOptions because each commit may have touched different files
    //     if (typeof newValue !== 'string') {
    //         newValue = newValue.value;
    //     }
    //     let index = this.filenamesToProtoFilenameAndDiffObjectsIndex.get(newValue);
    //     if (index !== undefined)
    //     {
    //         this.currentSelectedFile = newValue;
    //         return this.selectAFile(newValue);
    //     }
    //     return this.selectACommit(newValue, this.currentSelectedFile);
    // }

    _populateProtoFilenameAndDiffObjectsAndMap(arrayOfProtoFilenameAndDiff, setState) {
        this.fileOptions.options = [];
        this.protoFilenameAndDiffObjects = [];
        this.filenamesToProtoFilenameAndDiffObjectsIndex.clear();
        let firstFilename;
        for (let i = 0 ; i < arrayOfProtoFilenameAndDiff.length; i++) {
            let protoFilenameAndDiff = arrayOfProtoFilenameAndDiff[i];
            let protoFilenameAndDiffObject = protoFilenameAndDiff.toObject();
            this.protoFilenameAndDiffObjects.push(protoFilenameAndDiffObject);
            this.filenamesToProtoFilenameAndDiffObjectsIndex.set(protoFilenameAndDiffObject.filename, this.protoFilenameAndDiffObjects.length - 1);
            console.log(protoFilenameAndDiffObject);
            if (i === 0) {
                firstFilename = protoFilenameAndDiffObject.filename;
            }
            this.fileOptions.options.push({ value: protoFilenameAndDiffObject.filename, label: protoFilenameAndDiffObject.filename, color: '#666666' });
        }
        if (setState) {
            this.setState((state, props) => ({
                groupedOptions: [
                    {
                        label: 'Files',
                        options: this.fileOptions.options,
                    },
                    {
                        label: 'Commits',
                        options: this.commitOptions.options,
                    },
                ]
            }));
        }

        return firstFilename;
    }

    // componentDidMount() {
    //     this.setState((state, props) => ({
    //         groupedOptions: [
    //             {
    //                 label: 'Files',
    //                 options: this.fileOptions.options,
    //             },
    //             {
    //                 label: 'Commits',
    //                 options: this.commitOptions.options,
    //             },
    //         ]
    //     }));
    // }

    render() {
        document.getElementById("containerWrapper").style.height = `${(window.innerHeight - 39)}px`;
        // Setup the editors
        this.leftEditor = ace.edit('leftEditor');
        this.rightEditor = ace.edit('rightEditor');
        this.leftEditor.setTheme('ace/theme/xcode');
        this.rightEditor.setTheme('ace/theme/xcode');
        this.leftEditor.session.setUseWorker(false);
        this.rightEditor.session.setUseWorker(false);
        this.leftEditor.setReadOnly(true);
        this.rightEditor.setReadOnly(true);
        this.leftEditor.setOption("scrollPastEnd", 1);
        this.rightEditor.setOption("scrollPastEnd", 1);
        // turn off fold widgets until we fix https://gitlab.com/devdiff/diff/-/issues/30
        this.leftEditor.setShowFoldWidgets(false);
        this.rightEditor.setShowFoldWidgets(false);

        this._fillGutterWithSpans();
        let filename = window.location.pathname;
        console.log(`filename = ${filename}`);

        // axios.get(`http://127.0.0.1:3000/data${filename}`,
        axios.get(`https://vpz1zjp1sk.execute-api.us-west-2.amazonaws.com/v1/s3?key=difflens-prod${filename}`,
        {
            // responseType: 'arraybuffer'
            headers: {
                'Accept': 'application/json'
            }
        }).then(get=> {
            let buffer = get.data;
            let decoded = Buffer.from(buffer, 'base64');
            let contents = new Uint8Array(decoded);
            let diffs = proto.ProtoArrayOfFilenameAndDiff.deserializeBinary(contents);
            let arrayOfProtoFilenameAndDiff = diffs.getFilenameanddiffList();
            this.installationId = diffs.getInstallationid();
            this.owner = diffs.getOwner();
            this.repo = diffs.getRepo();
            this.pull_number = diffs.getPullnumber();
            let firstFilename = "";
            this.dropdownValueAndLabels = [];
            
            firstFilename = this._populateProtoFilenameAndDiffObjectsAndMap(arrayOfProtoFilenameAndDiff, false);
            if (firstFilename !== "") {
                axios.get(`https://wr57ntyz72.execute-api.us-west-2.amazonaws.com/data`,
                    {
                        headers: {
                            'Accept': 'application/json'
                        },
                        params: {
                            filename: firstFilename,
                            owner: this.owner,
                            repo: this.repo,
                            pull: this.pull_number,
                            installation: this.installationId,
                            filestatus: this.protoFilenameAndDiffObjects[0].prfilecommitstatus
                        }
                    }).then(async fileContents => {
                        let response = fileContents.data;
                        let baseContents = response.base;
                        let headContents = response.head;
                        let arrayIndex = this.filenamesToProtoFilenameAndDiffObjectsIndex.get(firstFilename);
                        let protoObject = this.protoFilenameAndDiffObjects[arrayIndex];
                        protoObject.basecontents = baseContents;
                        protoObject.headcontents = headContents;
                        let obj = {
                            head: headContents,
                            base: baseContents
                        };
                        this.filenameToBaseAndHeadContents.set(firstFilename, obj);
                        let arrayOfCommitMessages = diffs.getCommitmessageList();
                        // TODO: Continue from here before selectAFile above. We need to populate this in reverse order and highlight it in the drop down so users know which commit is the currently selected one
                        for (let i = 0; i < arrayOfCommitMessages.length; i++) {
                            this.commits.push(arrayOfCommitMessages[i]);
                            this.commitMessageToCommitsArrayIndex.set(arrayOfCommitMessages[i], this.commits.length - 1);
                            this.commitOptions.options.push({ value: arrayOfCommitMessages[i], label: arrayOfCommitMessages[i], color: '#666666' });
                        }

                        this.state.groupedOptions = [
                            {
                                label: 'Files',
                                options: this.fileOptions.options,
                            },
                            {
                                label: 'Commits',
                                options: this.commitOptions.options,
                            },
                        ];
                        let groupedOptionsLocal = [
                            {
                                label: 'Files',
                                options: this.fileOptions.options,
                            },
                            {
                                label: 'Commits',
                                options: this.commitOptions.options,
                            },
                        ];

                        // TODO: Assumes that we have at least 1 file to show in the diff. Fix this later
                        ReactDOM.render(
                            <React.StrictMode>
                                <App
                                    // dropdownValueAndLabels={this.state.groupedOptions}
                                    dropdownValueAndLabels={groupedOptionsLocal}
                                    handleFileClick={(newValue) => this.handleFileClick(newValue)}
                                    handleCommitClick={(newValue, firstFile) => this.handleCommitClick(newValue, firstFile)}
                                    owner={this.owner}
                                    repo={this.repo}
                                    pull_number={this.pull_number}
                                    installationId={this.installationId}
                                />
                            </React.StrictMode>,
                            document.getElementById('reactContainer')
                        );
                        await this.handleFileClick(firstFilename);
                    });
            }
        });
        // TODO: Handle the else condition


        return;
    }
}

var diffObject = new Diff();
diffObject.render();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
