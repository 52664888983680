import './App.css';
import React from 'react';
import { Component } from 'react';
import 'bulma/css/bulma.min.css';
import HeaderContainer from './HeaderContainer';
const proto = require('./globalMessages_pb');
const axios = require('axios').default;

function onChange(newValue) {
    console.log("change", newValue);
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
            groupedOptions: props.dropdownValueAndLabels,
            loading: false,
        }

        this.filenames = new Map()
        for (let i = 0; i < props.dropdownValueAndLabels[0].options.length; i++) {
            this.filenames.set(props.dropdownValueAndLabels[0].options[i].value, props.dropdownValueAndLabels[0].options[i]);
        }

        this.originalCommitOptions = props.dropdownValueAndLabels[1].options;
    }

    componentDidMount() {
        this.setState({
            selectedOption: this.props.dropdownValueAndLabels[0].options[0],
            groupedOptions: this.props.dropdownValueAndLabels,
            loading: false,
        });
    }

    _populateDropdownValueAndLabelsFromProto(arrayOfProtoFilenameAndDiff) {
        let localFilenameOptions = [];
        let firstFile = "";
        for (let i = 0 ; i < arrayOfProtoFilenameAndDiff.length; i++) {
            let protoFilenameAndDiff = arrayOfProtoFilenameAndDiff[i];
            let protoFilenameAndDiffObject = protoFilenameAndDiff.toObject();
            localFilenameOptions.push({value: protoFilenameAndDiffObject.filename, label: protoFilenameAndDiffObject.filename});
            if (i === 0) {
                firstFile = protoFilenameAndDiffObject.filename;
            }
        }

        let cachedCommitOptions = this.state.groupedOptions[1].options;
        let localGroupedOptions = [
            {
                label: 'Files',
                options: localFilenameOptions,
            },
            {
                label: 'Commits',
                options: cachedCommitOptions,
            },
        ]
        this.setState({
            selectedOption: localFilenameOptions[0],
            groupedOptions: localGroupedOptions
        });

        return firstFile;
    }

    _getCommitOptionsForSelectCommit(value) {
        let commitOptions = [];
        for (let i = 0; i < this.originalCommitOptions.length; i++) {
            if (this.originalCommitOptions[i].value === value) {
                commitOptions.push({ value: "* " + value, label: "* " + value, color: '#666666'})
            } else {
                commitOptions.push({ value: this.originalCommitOptions[i].value, label: this.originalCommitOptions[i].value, color: '#666666'})
            }
        }

        return commitOptions;
    }

    async handleDropdownClick(newValue) {
        let value = newValue.value;
        if (this.filenames.has(value)) {
            let setValue = this.filenames.get(value);
            // Filename
            this.setState((state, props) => ({
                selectedOption: setValue,
            }));
            await this.props.handleFileClick(value);
        } else {
            // Commit
            let split = value.split(' ');
            let commitSha = split[split.length - 1];
            let newCommitOptions = this._getCommitOptionsForSelectCommit(value);
            let localGroupedOptions = [
                {
                    label: 'Files',
                    options: this.state.groupedOptions[0].options,
                },
                {
                    label: 'Commits',
                    options: newCommitOptions,
                },
            ]
            this.setState({
                loading: true,
                groupedOptions: localGroupedOptions
            })
            // axios.get(`http://127.0.0.1:3000/commit/${commitSha}/owner/${this.props.owner}/repo/${this.props.repo}/pull/${this.props.pull_number}/installation/${this.props.installationId}`,
            axios.get(`https://wr57ntyz72.execute-api.us-west-2.amazonaws.com/commit/${commitSha}/owner/${this.props.owner}/repo/${this.props.repo}/pull/${this.props.pull_number}/installation/${this.props.installationId}`,
            {
                // responseType: 'arraybuffer'
                headers: {
                    'Accept': 'application/json'
                }
            }).then(async get=> { 
                let buffer = get.data;
                let decoded = Buffer.from(buffer, 'base64');
                let contents = new Uint8Array(decoded);
                let diffs = proto.ProtoArrayOfFilenameAndDiff.deserializeBinary(contents);
                let arrayOfProtoFilenameAndDiff = diffs.getFilenameanddiffList();
                let firstFile = this._populateDropdownValueAndLabelsFromProto(arrayOfProtoFilenameAndDiff);
                await this.props.handleCommitClick(arrayOfProtoFilenameAndDiff, firstFile);
                this.setState({
                    loading: false,
                })
            });
        }
    }

    render() {
        return (
                <div id="headerContainer">
                    <HeaderContainer 
                    groupedOptions={this.state.groupedOptions} 
                    selectedOption={this.state.selectedOption}
                    handleDropdownClick={(newValue) => this.handleDropdownClick(newValue)}
                    loading={this.state.loading}
                    />
                </div>
        );

    }
}

export default App;
