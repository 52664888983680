const throttle = require('lodash/throttle');

// inspired by https://github.com/ukandrewc/Ace-Scrollbars

export default function AceScrollbar(e, highlightLines, isLeft) {

	// Add CSS scrollbars
	e.container.insertAdjacentHTML("beforeend", `<div id='ace_pre-v-${isLeft ? 'left' : 'right'}' class='ace_scroll-v-${isLeft ? 'left' : 'right'}'></div><div id='ace_bar-v-${isLeft ? 'left' : 'right'}' class='ace_scroll-v ace_thumb-v'></div>`)
	e.container.insertAdjacentHTML("beforeend", `<div id='ace_pre-h-${isLeft ? 'left' : 'right'}' class='ace_scroll-h-${isLeft ? 'left' : 'right'}'></div><div id='ace_bar-h-${isLeft ? 'left' : 'right'}' class='ace_scroll-h ace_thumb-h'></div>`)
	e.container.insertAdjacentHTML("beforeend", `<div id='ace_map-row-${isLeft ? 'left' : 'right'}' class='ace_map-row'></div>`)
	e.container.insertAdjacentHTML("beforeend", `<div id='ace_map-${isLeft ? 'left' : 'right'}'></div>`)

	// Ace scrollbars
	let gWidth = 34
	let sc = document.getElementsByClassName("ace_scrollbar")

	let ro = new MutationObserver(function () {
		resizeScroll()
	})

	// Resize
	window.addEventListener("resize", function () {
		resizeScroll()
	})

	ro.observe(e.container, { characterData: true })
	for (let s of sc) {
		ro.observe(s.firstElementChild, { attributes: true })
	}

	// Map
	let mr = document.getElementById(`ace_map-row-${isLeft ? 'left' : 'right'}`)
	let mc = document.getElementById(`ace_map-${isLeft ? 'left' : 'right'}`)

	// Vertical
	let rScale = 1
	let vScale = 1
	let vStart = -1

	let vs = sc[0 + isLeft ? 0 : 2]
	let tv = document.getElementById(`ace_bar-v-${isLeft ? 'left' : 'right'}`)

	vs.addEventListener("scroll", throttle(
        () => {
		    tv.style.top = vs.scrollTop * vScale + "px"
	    }
    , 10, {leading: true}))

	// Drag events
	tv.addEventListener("pointerdown", function (e) {
		tv.setPointerCapture(e.pointerId)
		vStart = e.clientY
	})

	tv.addEventListener("pointermove", function (e) {
		if (vStart !== -1) {
			vs.scrollTop += (e.clientY - vStart) / vScale
			vStart = e.clientY
		}
	})

	tv.addEventListener("pointerup", function (e) {
		tv.releasePointerCapture(e.pointerId)
		vStart = -1
	})

	tv.previousElementSibling.addEventListener("mousedown", function (e) {
		vs.scrollTop = e.clientY / vScale
	})

	function resizeScroll() {
		vScale = vs.clientHeight / vs.scrollHeight
		if (vs.clientHeight) {
			tv.style.height = (vs.clientHeight * vScale) + "px"
			showScroll(tv, true)
		}
		else {
			showScroll(tv, false)
		}

		rScale = e.renderer.lineHeight * vScale
		gWidth = e.renderer.gutterWidth
		showMapItems(e, highlightLines, isLeft)
	}

	function showScroll(e, s) {
		let d = (s) ? "" : "none"
		e.style.display = d
        if (e.previousElementSibling) {
            e.previousElementSibling.style.display = d
        }
	}

    resizeScroll();
    // showMapItems(e, highlightLines, isLeft);

    function showMapItems(e, highlightLines, isLeft) {
        let ht = ""
        let lr = -1
        for (let i = 0; i < highlightLines.length; i++) {
            let r = highlightLines[i];
            if (r !== lr) {
                ht += `<div class='ace_map-item-${isLeft ? "left" : "right"}' style='top:` + (r * rScale) + "px'></div>"
                lr = r
            }
        }
        mc.innerHTML = ht
        mr.style.top = e.getCursorPosition().row * rScale + "px"
    }
}